import React, { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import store from "./../../../store";

export function useRedirect() {
  const redirect = useSelector(state => state.page.redirect);
  return { redirect, setRedirect };
}

export function setRedirect(value) {
  store.dispatch({
    type: "SET_REDIRECT",
    redirect: value
  });
}
