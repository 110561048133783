import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";

export default function UploadDialog(props) {
  const { open, onClose, title } = props;

  // TODO: Add "Upload in Progress" support

  let filesInput = React.createRef();

  return (
    <Dialog open={open} onClose={() => onClose(null)}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>Select file(s) to upload</DialogContentText>
        <Input
          type="file"
          inputRef={filesInput}
          inputProps={{ multiple: true }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onClose(filesInput.current.files)}
          disableRipple={true}
        >
          OK
        </Button>
        <Button
          onClick={() => {
            onClose(null);
          }}
          disableRipple={true}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
