import React from "react";
import { Typography, Modal } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ExpandIcon from "@material-ui/icons/ChevronLeft";
import CollapseIcon from "@material-ui/icons/ChevronRight";
import "./SideDialog.scss";

export default function SideDialog(props) {
  const { title, isOpen, setIsOpen, isExpanded: initIsExpanded } = props;

  const [isExpanded, setIsExpanded] = React.useState(initIsExpanded);

  const sizeClass = isExpanded
    ? "side-dialog-container-expanded"
    : "side-dialog-container-collapsed";

  return isOpen ? (
    <Modal
      open={isOpen}
      className={`side-dialog-modal`}
      BackdropProps={{ invisible: true }}
    >
      <div className={`side-dialog-container ${sizeClass}`}>
        <div className="side-dialog-layout-grid">
          <div className="header-row">
            <Typography className="title" variant="h5">
              {title}
            </Typography>
            {isExpanded ? (
              <CollapseIcon
                className="header-icon"
                onClick={() => setIsExpanded(false)}
              />
            ) : (
              <ExpandIcon
                className="header-icon"
                onClick={() => setIsExpanded(true)}
              />
            )}
            <CloseIcon
              className="header-icon"
              onClick={() => setIsOpen(false)}
            />
          </div>
          <div className="content">{props.children}</div>
        </div>
      </div>
    </Modal>
  ) : null;
}
