import React, { useEffect } from "react";

export function useFilteredAssets(assets, filterString) {
  const [filteredAssets, setFilteredAssets] = React.useState([]);

  function includes(string, substring) {
    return string.toLowerCase().includes(substring.toLowerCase());
  }
  useEffect(() => {
    if (!filterString || !assets) {
      setFilteredAssets(assets);
    }
    setFilteredAssets(
      assets.filter(asset => {
        if (includes(asset.name, filterString)) {
          return true;
        }
        return false;
      })
    );
  }, [filterString, assets]);

  return { filteredAssets, setFilteredAssets };
}
