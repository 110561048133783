import { useCallback } from "react";
import Backend from "./../../backend";

export function useMoveCard(items, setItems, storyboardId) {
  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      if (hoverIndex === undefined) {
        hoverIndex = items.length;
      }

      let insertIndex = hoverIndex;
      if (dragIndex < insertIndex) {
        insertIndex -= 1;
      }

      const reorderedItems = [...items];
      reorderedItems.splice(
        insertIndex,
        0,
        reorderedItems.splice(dragIndex, 1)[0]
      );

      setItems(reorderedItems);
      Backend.updateStoryboardOrderedFrames(storyboardId, reorderedItems);
    },
    [items]
  );
  return moveCard;
}
