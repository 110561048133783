const initialState = {
  help: { open: false }
};

const ui = (state = initialState, action) => {
  switch (action.type) {
    case "SET_HELP_OPEN":
      return {
        ...state,
        help: { open: action.open }
      };

    default:
      return state;
  }
};

export default ui;
