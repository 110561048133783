import React from "react";
import UploadDialog from "./../Upload/UploadDialog";
import Backend from "../../../backend";

export default function BackgroundUploadDialog(props) {
  const { setOpen, projectId, onImageUploaded } = props;

  const onClose = files => {
    if (!files) {
      setOpen(false);
    } else {
      Promise.all(
        Object.values(files).map(file => {
          return Backend.addPrivateBackground(
            projectId,
            { name: file.name },
            file
          ).then(() => {
            if (onImageUploaded) {
              onImageUploaded();
            }
          });
        })
      ).then(() => setOpen(false));
    }
  };

  return (
    <UploadDialog {...props} title="Upload Backgrounds" onClose={onClose} />
  );
}
