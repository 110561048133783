import React from "react";
import { InputBase } from "@material-ui/core";
import FilterIcon from "@material-ui/icons/FilterList";

export default function Filter(props) {
  const { filterString, setFilterString } = props;
  return (
    <div className="filter-row">
      <FilterIcon className="filter-icon" />
      <InputBase
        className="filter-input"
        placeholder="Type to filter"
        value={filterString}
        onChange={event => {
          setFilterString(event.target.value);
        }}
      />
    </div>
  );
}
