import React, { useEffect } from "react";

import ProjectCard from "./ProjectCard";
import NewProjectCard from "./NewProjectCard";

import { setRedirect } from "./../Utility/Redirect/hooks";
import { useProjects } from "./hooks";
import "./ProjectsGrid.scss";

// TODO: Fix grid layout (regular spacing, whole grid aligned in center, items within grid aligned to left)
export default function ProjectsGrid(props) {
  const { value: projects, setDoFetch, hasFetched } = useProjects();

  useEffect(() => {
    setDoFetch(true);
  }, [setDoFetch]);

  return hasFetched ? (
    <div className="project-grid">
      {projects
        .sort((a, b) => (a.creation_time > b.creation_time ? 1 : -1))
        .map(project => {
          return <ProjectCard key={project.id} project={project} />;
        })}

      <NewProjectCard
        onNewProjectCreated={projectId =>
          setRedirect({ to: `/project/${projectId}` })
        }
      />
    </div>
  ) : null;
}
