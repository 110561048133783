import React, { useEffect } from "react";
import { useBackgrounds } from "../Backgrounds/hooks";
import Gallery from "../Gallery/Gallery";
import "./BackgroundGallery.scss";

export default function BackgroundGallery(props) {
  const { projectId, onAssetClicked, refresh, setRefresh } = props;
  const { value: backgrounds, setDoFetch } = useBackgrounds(projectId);

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
      setDoFetch(true);
    }
  }, [refresh, setRefresh, setDoFetch]);

  return (
    <Gallery
      assets={backgrounds}
      onAssetClicked={onAssetClicked}
      loadAssets={setDoFetch}
    />
  );
}
