import React from "react";
import { Button, Typography, Divider } from "@material-ui/core";
import "./PropManager.scss";
import PropUploadDialog from "./PropUploadDialog";
import SideDialog from "../../Utility/SideDialog/SideDialog";
import PixabayGallery from "../Pixabay/PixabayGallery";
import PropGallery from "../Props/PropGallery";
import Backend from "./../../../backend";

export default function PropManager(props) {
  const { projectId } = props;

  const [isUploadOpen, setIsUploadOpen] = React.useState(false);
  const [isPixabayOpen, setIsPixabayOpen] = React.useState(false);

  const [refresh, setRefresh] = React.useState(false);

  const onPixabayImageClicked = image => {
    return Backend.pixabayImport(projectId, image, "prop")
      .then(() => {
        setRefresh(true);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const onImageUploaded = () => {
    setRefresh(true);
  };

  return (
    <>
      <div className="prop-manager">
        <Typography key="title" className="title" variant="h4">
          Manage Project Props
        </Typography>
        <Divider />
        <div className="main-actions-bar">
          <Typography variant="body1">Import from:</Typography>
          <>
            <Button
              className="main-action-button"
              variant="text"
              size="large"
              disableRipple={true}
              onClick={() => setIsPixabayOpen(!isPixabayOpen)}
            >
              Pixabay
            </Button>
            <SideDialog
              title={"Search Pixabay"}
              isOpen={isPixabayOpen}
              setIsOpen={setIsPixabayOpen}
            >
              <PixabayGallery
                onImageClicked={onPixabayImageClicked}
                searchForIsolatedOnly={true}
              />
            </SideDialog>
          </>
          <>
            <Button
              className="main-action-button"
              variant="text"
              size="large"
              disableRipple={true}
              onClick={() => setIsUploadOpen(!isUploadOpen)}
            >
              Upload
            </Button>
            <PropUploadDialog
              projectId={projectId}
              open={isUploadOpen}
              setOpen={setIsUploadOpen}
              onImageUploaded={onImageUploaded}
            />
          </>
        </div>
        <Divider />
        <PropGallery
          projectId={projectId}
          onAssetClicked={() => null}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </div>
    </>
  );
}
