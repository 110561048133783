import React from "react";

import { TextField } from "@material-ui/core";

export default function OutlinerInstanceText(props) {
  const { value, saveValue, selected } = props;

  const [_value, _setValue] = React.useState(value);
  const [_disabled, _setDisabled] = React.useState(true);
  const inputRef = React.useRef(null);

  function _saveValue(name) {
    if (saveValue) {
      return saveValue(name);
    }
  }

  React.useEffect(() => {
    _setValue(value);
  }, [value]);

  React.useEffect(() => {
    if (!_disabled) {
      inputRef.current.focus();
    }
  }, [_disabled]);

  React.useEffect(() => {
    if (!selected) {
      _setDisabled(true);
    }
  }, [selected]);

  return (
    <div className="outliner-instance-text">
      <TextField
        variant="standard"
        margin="dense"
        disabled={_disabled}
        InputProps={{ disableUnderline: true }}
        InputLabelProps={{
          shrink: true,
          focused: false
        }}
        inputRef={inputRef}
        fullWidth={true}
        inputProps={{ rows: 2 }}
        value={_value}
        onBlur={() => saveValue(_value)}
        onChange={e => _setValue(e.target.value)}
        onClick={() => {
          if (selected) {
            inputRef.current.focus();
            _setDisabled(!_disabled);
            inputRef.current.focus();
          }
        }}
        onKeyPress={e => {
          if (e.key === "Enter") {
            _saveValue(_value);
            _setDisabled(true);
          }
        }}
      />
    </div>
  );
}
