import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useMostRecentFrames } from "../components/Frame/hooks";
import { useAllProjects } from "../components/Projects/hooks";
import PreviewImage from "../components/Storyboards/PreviewImage";
import Backend from "./../backend";
import "./AdminFrames.scss";

export default function AdminFramesPage(props) {
  const limit = 200;
  const { value: frames, setDoFetch: setDoFetchFrames } = useMostRecentFrames(
    limit
  );
  const { value: projects, setDoFetch: setDoFetchProjects } = useAllProjects();

  useEffect(() => {
    setDoFetchFrames(true);
    setDoFetchProjects(true);
  }, [setDoFetchFrames, setDoFetchProjects]);

  const [users, setUsers] = React.useState({});
  useEffect(() => {
    if (frames && projects) {
      const projectIds = frames.map(frame => frame.projectId);
      projects
        .filter(project => projectIds.includes(project.id))
        .forEach(project => {
          Backend.getUserInfo(project.creator).then(user => {
            setUsers(prevState => {
              return { ...prevState, [project.id]: user };
            });
          });
        });
    }
  }, [frames, projects]);

  return (
    <div className="admin-frames-page">
      <div className="table">
        {(frames || []).map(frame => {
          return (
            <React.Fragment key={`${frame.id}`}>
              <div className="cell">
                <Link to={`/frame/${frame.id}`}>
                  <PreviewImage url={frame.thumbnail} />
                </Link>
              </div>
              <div className="cell">{frame.description}</div>
              <div className="cell">
                {users[frame.projectId] && users[frame.projectId].email}
              </div>
              <div className="cell">
                <Link to={`/project/${frame.projectId}`}>
                  {frame.projectId}
                </Link>
              </div>
              <div className="cell">
                <Link to={`/storyboard/${frame.storyboardId}`}>
                  {frame.storyboardId}
                </Link>
              </div>
              <div className="cell">
                {new Date(
                  frame._updated || frame.thumbnailTimestamp
                ).toUTCString()}
              </div>
              <div className="cell">{frame.archived ? "ARCHIVED" : "LIVE"}</div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}
