import React from "react";
import { withRouter } from "react-router-dom";

function SegmentBrowser(props) {
  props.history.listen((location, action) => {
    if (window.analytics) {
      window.analytics.page({
        action: action
      });
    }
  });
  return <></>;
}
export default withRouter(SegmentBrowser);
