import React from "react";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import PreviewImage from "./PreviewImage";
import "./FrameCard.scss";

export default function FrameCard(props) {
  const { frame, showTitle } = props;

  const displayName =
    frame && frame.name === "Untitled frame" ? "" : frame.name;

  const thumbnailOutOfDate =
    frame.fdl &&
    (!frame.thumbnail || frame.fdl.timestamp > frame.thumbnailTimestamp);

  return (
    <div className="frame-card">
      <Link className="frame-card-a" to={`/frame/${frame.id}`}>
        {showTitle && (
          <Typography className="frame-title" variant="body1">
            {displayName}
          </Typography>
        )}

        <PreviewImage url={frame.thumbnail} />

        {thumbnailOutOfDate ? (
          <div className="frame-updating-info">
            <Typography variant="body1" align="center">
              Preview image is being updated
            </Typography>
          </div>
        ) : null}
      </Link>
    </div>
  );
}
