import React from "react";
import { useSelector } from "react-redux";

import CreateStoryboardIcon from "@material-ui/icons/AddPhotoAlternate";

import Toolbox from "./../../Toolbox/Toolbox";
import ToolboxItem from "./../../Toolbox/ToolboxItem";
import Backend from "../../../backend";

function CreateStoryboard(props) {
  const { onStoryboardCreated } = props;
  const sessionIds = useSelector(state => state.session.sessionIds);
  const projectId = sessionIds ? sessionIds.projectId : null;

  const [inProgress, setInProgress] = React.useState(false);

  return (
    <ToolboxItem
      tooltip="Create a new storyboard"
      inProgress={inProgress}
      onClick={() => {
        if (projectId) {
          setInProgress(true);
          Backend.createStoryboard(projectId)
            .then(() => onStoryboardCreated())
            .finally(() => setInProgress(false));
        }
      }}
    >
      <CreateStoryboardIcon />
    </ToolboxItem>
  );
}

export default function ProjectToolbox(props) {
  const { onStoryboardCreated } = props;
  return (
    <Toolbox>
      <CreateStoryboard onStoryboardCreated={onStoryboardCreated} />
    </Toolbox>
  );
}
