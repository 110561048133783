import React, { useEffect } from "react";
import { useBackgrounds } from "./hooks";
import Gallery from "../Gallery/Gallery";
import PixabayGallery from "./../Pixabay/PixabayGallery";
import "./BackgroundMultiGallery.scss";
import { Tabs, Tab } from "@material-ui/core";
import Backend from "./../../../backend";

function PublicBackgrounds(props) {
  const { onAssetClicked } = props;
  const { value: backgrounds, setDoFetch } = useBackgrounds("public");
  useEffect(() => {
    setDoFetch(true);
  }, []);

  return <Gallery assets={backgrounds} onAssetClicked={onAssetClicked} />;
}

function ProjectBackgrounds(props) {
  const { projectId, onAssetClicked } = props;
  const { value: backgrounds, setDoFetch } = useBackgrounds(projectId);

  useEffect(() => {
    setDoFetch(true);
  }, []);

  return <Gallery assets={backgrounds} onAssetClicked={onAssetClicked} />;
}

export default function BackgroundMultiGallery(props) {
  const { projectId, onAssetClicked } = props;
  // const { value: backgrounds, setDoFetch } = useBackgrounds(projectId);

  const [currentTab, setCurrentTab] = React.useState(0);

  const tabs = ["My Project", "Viso Labs", "Search"];

  function onImageClicked(image) {
    return Backend.pixabayImport(projectId, image, "background")
      .then(assetId => {
        return Backend.__getBackground(projectId, assetId);
      })
      .then(asset => {
        onAssetClicked(asset);
      })
      .catch(error => {
        console.log(error);
      });
  }

  return (
    <div
      className="background-multi-gallery"
      // container
      // item
      // direction="column"
    >
      <Tabs
        className="background-tabs"
        value={currentTab}
        onChange={(e, v) => {
          setCurrentTab(v);
        }}
      >
        {tabs.map(name => (
          <Tab
            key={name}
            className="background-tab"
            label={name}
            disableRipple
            disableFocusRipple
          />
        ))}
      </Tabs>
      {(tabs[currentTab] === "My Project" && (
        <ProjectBackgrounds
          projectId={projectId}
          onAssetClicked={onAssetClicked}
        />
      )) ||
        (tabs[currentTab] === "Viso Labs" && (
          <PublicBackgrounds onAssetClicked={onAssetClicked} />
        )) ||
        (tabs[currentTab] === "Search" && (
          <PixabayGallery
            onImageClicked={onImageClicked}
            searchForIsolatedOnly={false}
          />
        ))}
    </div>
  );
}
