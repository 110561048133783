import { combineReducers } from "redux";
import fdl from "./fdl";
import frameEditor from "./frameEditor";
import session from "./session";
import page from "./page";
import ui from "./ui";

export default combineReducers({
  fdl,
  frameEditor,
  session,
  page,
  ui
});
