import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";

import store from "../store";

import config from "./../config.json";

function parseJwt(token) {
  // Source: https://stackoverflow.com/a/38552302
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function(c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

// TODO: Fix up how create/manage firebase singletons
// The Firebase class was taken from a react tutorial.
// We also want to access the same firebase app as a backend library
class FirebaseSingleton {
  constructor() {
    firebase.initializeApp(config.firebase);
    this.app = firebase;
    this.auth = firebase.auth();
    this.db = firebase.firestore();
    this.storage = firebase.storage();

    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.db
          .collection("users")
          .doc(authUser.uid)
          .onSnapshot(change => {
            authUser.getIdToken(true).then(token => {
              const parsedToken = parseJwt(token);
              const claims = {
                admin: parsedToken.admin || false,
                approved: parsedToken.approved || false
              };
              store.dispatch({
                type: "SET_USER",
                user: { ...authUser.toJSON(), claims: claims }
              });
            });
          });
      } else {
        store.dispatch({
          type: "SET_USER",
          user: null
        });
      }
    });
  }
}

const firebaseSingleton = new FirebaseSingleton();

class Firebase {
  constructor() {
    this.app = firebaseSingleton.app;
    this.auth = firebaseSingleton.auth;
    this.db = firebaseSingleton.db;
    this.storage = firebaseSingleton.storage;
  }
}

export default Firebase;
