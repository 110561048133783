import React from "react";
import AppBar from "../components/AppBar/AppBar";
import Project from "../components/Projects/Project";
import { useInitSessionIdsFromParams } from "./../components/Utility/Session/hooks";

export default function ProjectPage(props) {
  useInitSessionIdsFromParams(props);

  return (
    <div>
      <AppBar />
      <Project />
    </div>
  );
}
