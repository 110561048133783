import undoable, { groupByActionTypes } from "redux-undo";
import _ from "lodash";

// TODO: Reuse this with index.js
const initialFrameState = {
  description: "",
  dialogue: "",
  backgrounds: {},
  props: {},
  characters: {},
  // layout: {},
  // mapping: {},
  initialState: true,
  loadedState: false
};

function updateStateAttribute(state, attrName, value) {
  if (state[attrName] === value) {
    return state;
  }
  return {
    ...state,
    [attrName]: value,
    initialState: false,
    loadedState: false
  };
}

const fdl = (state = initialFrameState, action) => {
  let updatedCharacters, updatedBackgrounds, updatedProps;

  switch (action.type) {
    case "CLEAR_FRAME":
      return { ...initialFrameState };

    case "LOAD_FRAME":
      return {
        ...initialFrameState,
        ...action.fdl,
        initialState: false,
        loadedState: true
      };

    case "ADD_BACKGROUND":
      updatedBackgrounds = {
        [action.backgroundId]: {
          asset: action.background,
          src: action.background.images.default.src,
          depth: 10
        },
        ...state.backgrounds
      };
      return updateStateAttribute(state, "backgrounds", updatedBackgrounds);

    case "ADD_PROP":
      updatedProps = {
        [action.propId]: {
          asset: action.prop,
          src: action.prop.images.default.src,
          depth: 50
        },
        ...state.props
      };
      return updateStateAttribute(state, "props", updatedProps);

    case "ADD_CHARACTER":
      updatedCharacters = {
        [action.charId]: {
          asset: action.character,
          src: action.character.images.default.src,
          depth: 90
        },
        ...state.characters
      };
      return updateStateAttribute(state, "characters", updatedCharacters);

    case "DELETE_CHARACTER":
      updatedCharacters = {
        ...state.characters
      };
      delete updatedCharacters[action.charId];
      return updateStateAttribute(state, "characters", updatedCharacters);

    case "DELETE_BACKGROUND":
      updatedBackgrounds = {
        ...state.backgrounds
      };
      delete updatedBackgrounds[action.backgroundId];
      return updateStateAttribute(state, "backgrounds", updatedBackgrounds);

    case "DELETE_PROP":
      updatedProps = {
        ...state.props
      };
      delete updatedProps[action.propId];
      return updateStateAttribute(state, "props", updatedProps);

    case "UPDATE_CHARACTER":
      // Update alt image if appropriate
      let updates = { ...action.updates };

      if (updates.alt) {
        updates.alt.height = updates.alt.hasOwnProperty("height")
          ? updates.alt.height
          : (state.characters[action.charId].alt &&
              state.characters[action.charId].alt.height) ||
            "normal";
        updates.alt.angle = updates.alt.hasOwnProperty("angle")
          ? updates.alt.angle
          : (state.characters[action.charId].alt &&
              state.characters[action.charId].alt.angle) ||
            0;

        const matchingAltImages = state.characters[
          action.charId
        ].asset.images.alt.filter(
          image =>
            image.height === updates.alt.height &&
            image.angle === updates.alt.angle
        );
        if (matchingAltImages.length > 0) {
          updates.src = matchingAltImages[0].src;
        }
      } else if (action.updates.asset) {
        const height = state.characters[action.charId].alt.height;
        const angle = state.characters[action.charId].alt.angle;

        const matchingAltImages = action.updates.asset.images.alt.filter(
          image => image.height === height && image.angle === angle
        );

        if (matchingAltImages.length > 0) {
          updates.src = matchingAltImages[0].src;
        }
      }

      updatedCharacters = {
        ...state.characters,
        [action.charId]: {
          ...state.characters[action.charId],
          ...updates
        }
      };

      return updateStateAttribute(state, "characters", updatedCharacters);

    case "UPDATE_BACKGROUND":
      if (action.updates.asset) {
        action.updates.src = action.updates.asset.images.default.src;
      }
      updatedBackgrounds = {
        ...state.backgrounds,
        [action.backgroundId]: {
          ...state.backgrounds[action.backgroundId],
          ...action.updates
        }
      };
      return updateStateAttribute(state, "backgrounds", updatedBackgrounds);

    case "UPDATE_PROP":
      if (action.updates.asset) {
        action.updates.src = action.updates.asset.images.default.src;
      }
      updatedProps = {
        ...state.props,
        [action.propId]: {
          ...state.props[action.propId],
          ...action.updates
        }
      };
      return updateStateAttribute(state, "props", updatedProps);

    case "UPDATE_CAMERA_MOVE":
      return updateStateAttribute(state, "cameraMove", action.cameraMove);

    default:
      return state;
  }
};

const undoableFdl = undoable(fdl, {
  limit: 50,
  ignoreInitialState: true,

  // Group actions into single undo/redo states
  groupBy: (action, currentState, previousHistory) => {
    if (action.type === "SET_DESCRIPTION" || action.type === "SET_DIALOGUE") {
      return action.type;
    }

    // Group some property updates into single events:
    // These can update from slider controls, which can trigger many
    // very quick updates in succession
    if (
      action.type === "UPDATE_BACKGROUND" ||
      action.type === "UPDATE_CHARACTER" ||
      action.type === "UPDATE_PROP"
    ) {
      if (Object.keys(action.updates).length === 1) {
        const propertyName = Object.keys(action.updates)[0];
        const groupedProperties = [
          "depth",
          "saturation",
          "brightness",
          "gammaR",
          "gammaG",
          "gammaB",
          "alt"
        ];
        if (groupedProperties.includes(propertyName)) {
          const instanceId =
            action.charId || action.backgroundId || action.propId;
          const group = `${action.type}-${instanceId}-${propertyName}`;
          return group;
        }
      }
    }
  },
  filter: (action, currentState, previousHistory) => {
    // LOAD_FRAME can easily resync the current fdl from the server
    // In these situations, where fdl hasn't changed, we don't include
    // the states in our undo/redo queue.
    if (action.type === "LOAD_FRAME") {
      if (_.isEqual(currentState, previousHistory.present)) {
        return false;
      }
    }
    return true;
  }
});

const filterInitialState = (state, action) => {
  // Remove the initialState from the past stack when we first load
  // a frame. This prevents undo-ing to a blank frame
  const newState = undoableFdl(state, action);
  if (newState.past.length === 1 && newState.past[0].initialState) {
    newState.past.pop();
  }
  return newState;
};

// Custom wrapper: Add timestamp to current fdl so we can compare
// with fdl that is received from the server
const timestampedFdl = (state, action) => {
  const newState = filterInitialState(state, action);
  if (newState.present && !newState.present.initialState) {
    newState.present.timestamp = new Date().getTime();
  }
  return newState;
};

export default timestampedFdl;
