import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useCheckUserValid } from "./hooks";

const PrivateRoute = ({ component: Component, ...props }) => {
  const { isUserValid, isUserLoading } = useCheckUserValid(props.admin);

  function getRenderComponent(props) {
    if (isUserLoading) {
      return null;
    } else if (isUserValid) {
      return <Component {...props} />;
    } else {
      return (
        <Redirect
          to={{ pathname: "/signin", state: { from: props.location } }}
        />
      );
    }
  }

  return <Route {...props} render={getRenderComponent} />;
};

export default PrivateRoute;
