const initialState = {
  sessionUser: {
    isLoading: true,
    isLoggedIn: false,
    user: null
  },
  sessionsIds: {
    projectId: null,
    storyboardId: null,
    frameId: null
  }
};

const session = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        sessionUser: {
          isLoading: false,
          isLoggedIn: !!action.user,
          user: action.user
        }
      };

    case "SET_SESSION_IDS":
      return {
        ...state,
        sessionIds: {
          projectId: action.projectId,
          storyboardId: action.storyboardId,
          frameId: action.frameId
        }
      };

    default:
      return state;
  }
};

export default session;
