import React from "react";
import { Button, Typography, Divider } from "@material-ui/core";
import "./CharacterManager.scss";
import CharacterUploadDialog from "./CharacterUploadDialog";
import CharacterGallery from "../Characters/CharacterGallery";

export default function CharacterManager(props) {
  const { projectId } = props;

  const onAssetClicked = asset => {
    console.log("TODO: CharacterManager.onAssetClicked", asset);
  };

  const [isUploadOpen, setIsUploadOpen] = React.useState(false);

  return (
    <>
      <div className="character-manager">
        <Typography key="title" className="title" variant="h4">
          Manage Project Characters
        </Typography>
        <Divider />
        <div className="main-actions-bar">
          <>
            <Button
              className="main-action-button"
              variant="text"
              size="large"
              disableRipple={true}
              onClick={() => setIsUploadOpen(!isUploadOpen)}
            >
              Upload
            </Button>
            <CharacterUploadDialog
              projectId={projectId}
              open={isUploadOpen}
              setOpen={setIsUploadOpen}
            />
          </>
        </div>
        <Divider />
        <CharacterGallery
          projectId={projectId}
          onAssetClicked={onAssetClicked}
        />
      </div>
    </>
  );
}
