import { createStore, applyMiddleware, compose } from "redux";

import rootReducer from "./reducers";

import segmentMiddleware from "./segment/middlware";

const initialState = {};

const middlewares = [applyMiddleware(segmentMiddleware)];

if (typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION__) {
  middlewares.push(window.__REDUX_DEVTOOLS_EXTENSION__());
}

const store = createStore(rootReducer, initialState, compose(...middlewares));

export default store;
