import React from "react";
import { useSelector } from "react-redux";
import OutlinerItem from "./../../Outliner/OutlinerItem";
import { Typography } from "@material-ui/core";
import { setRedirect } from "./../../Utility/Redirect/hooks";
import TeamIcon from "@material-ui/icons/People";
import { withRouter } from "react-router";

function TeamOutliner(props) {
  const sessionIds = useSelector(state => state.session.sessionIds);
  const projectId = sessionIds ? sessionIds.projectId : null;
  // const { users } = useProjectUsers(projectId);

  const url = `/project/${projectId}/team`;

  return (
    <div>
      <OutlinerItem
        onClick={() => {
          setRedirect({ to: url });
        }}
        selectable={true}
        selected={props.match.url === url}
      >
        <TeamIcon />
        <Typography className="outliner-text">Team</Typography>
      </OutlinerItem>
    </div>
  );
}

export default withRouter(TeamOutliner);
