import React from "react";

// TODO: Switch to reducer; Add erro handling
export function useFetch(queryFunction, defaultValue) {
  const reducer = (state, action) => {
    switch (action.type) {
      case "FETCH_REQUESTED":
        if (!state.doFetch) {
          return { ...state, doFetch: true };
        } else {
          return state;
        }
      case "FETCH_STARTED":
        if (!state.isFetching) {
          return { ...state, isFetching: true };
        } else {
          return state;
        }
      case "FETCH_COMPLETE":
        return {
          ...state,
          value: action.value,
          doFetch: false,
          isFetching: false,
          hasFetched: true
        };
      default:
        return state;
    }
  };
  const [state, dispatch] = React.useReducer(reducer, {
    value: defaultValue,
    doFetch: false,
    isFetching: false,
    hasFetched: false
  });

  React.useEffect(() => {
    if (state.doFetch && !state.isFetching) {
      dispatch({ type: "FETCH_STARTED" });
      queryFunction().then(result => {
        dispatch({ type: "FETCH_COMPLETE", value: result });
      });
    }
  }, [state.doFetch, state.isFetching, queryFunction]);

  const setDoFetch = React.useCallback(
    () => dispatch({ type: "FETCH_REQUESTED" }),
    []
  );

  return {
    value: state.value,
    doFetch: state.doFetch,
    isFetching: state.isFetching,
    hasFetched: state.hasFetched,
    setDoFetch
  };
}
