import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Typography, Divider, TextField } from "@material-ui/core";
import { useProjectUsers } from "./../Projects/hooks";
import "./TeamManager.scss";
import UserBadge from "./UserBadge";
import RemoveIcon from "@material-ui/icons/Clear";
import AddIcon from "@material-ui/icons/Add";
import Backend from "./../../backend";

function User(props) {
  const { user, projectId, onUserRemoved, adminMode } = props;

  const role = user.projects[projectId];

  return (
    <div className="user-item">
      <UserBadge className="badge" user={user} />
      <Typography className="name">
        {user.name} {role && `(${role})`}
      </Typography>
      <Typography className="email">{user.email}</Typography>
      {adminMode ? (
        <RemoveIcon
          className="remove"
          onClick={() =>
            Backend.removeUserFromProject(user.id, projectId).then(() =>
              onUserRemoved()
            )
          }
        />
      ) : (
        <div />
      )}
    </div>
  );
}

function NewUser(props) {
  const { projectId, onUserAdded } = props;
  const [newEmail, setNewEmail] = React.useState("");

  function addNewUser() {
    if (!projectId || !newEmail) {
      return;
    }
    Backend.addUserToProjectByEmail(newEmail, projectId)
      .then(() => {
        onUserAdded();
        setNewEmail("");
      })
      .catch(err => {
        alert(
          "ERROR. Has your friend signed up? Please ask them to sign up and try again."
        );
      });
  }
  return (
    <div className="new-user-item">
      <TextField
        className="new-user-input"
        variant="standard"
        margin="dense"
        InputProps={{ disableUnderline: true }}
        InputLabelProps={{
          shrink: true,
          focused: false
        }}
        placeholder={`Type an email address and press enter to add to this project`}
        value={newEmail}
        onChange={e => setNewEmail(e.target.value)}
        onKeyPress={e => e.key === "Enter" && addNewUser()}
      />
      <AddIcon className="add" onClick={() => addNewUser()} />
    </div>
  );
}
export default function TeamManager(props) {
  const { projectId } = props;
  const { value: users, setDoFetch } = useProjectUsers(projectId);
  useEffect(() => setDoFetch(true), [projectId, setDoFetch]);

  const session = useSelector(state => state.session.sessionUser);

  let isAdmin = false;
  users.forEach(user => {
    if (user.id === session.user.uid) {
      if (user.projects[projectId] === "admin") {
        isAdmin = true;
      }
    }
  });

  return (
    <div className="user-manager">
      <Typography key="title" className="title" variant="h4">
        Team Members
      </Typography>
      <Divider />
      {users.map(user => (
        <div key={user.id}>
          <User
            user={user}
            projectId={projectId}
            onUserRemoved={() => setDoFetch(true)}
            adminMode={isAdmin}
          />
          <Divider />
        </div>
      ))}
      {isAdmin ? (
        <NewUser
          key="new-user"
          projectId={projectId}
          onUserAdded={() => setDoFetch(true)}
        />
      ) : (
        <div />
      )}
    </div>
  );
}
