import React, { useEffect } from "react";
import PreviewImage from "./PreviewImage";
import Filter from "./Filter";
import { useFilteredAssets } from "./hooks";
import "./Gallery.scss";

export default function Gallery(props) {
  const { assets, onAssetClicked, loadAssets } = props;
  const [filterString, setFilterString] = React.useState("");
  const { filteredAssets } = useFilteredAssets(assets, filterString);

  // Trigger asset load when first rendered
  useEffect(() => {
    if (loadAssets) {
      loadAssets(true);
    }
  }, [loadAssets]);

  return (
    <div className={`asset-gallery`}>
      <Filter filterString={filterString} setFilterString={setFilterString} />
      <div className={`asset-grid`}>
        {filteredAssets.map(asset => {
          return (
            <div
              key={asset.id}
              className="asset-grid-item"
              onClick={() => onAssetClicked(asset)}
            >
              <PreviewImage
                url={
                  asset.thumbnail ||
                  (asset.images && asset.images.default.src) ||
                  null
                }
                text={asset.name}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
