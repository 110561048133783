import React from "react";
import AppBar from "../components/AppBar/AppBar";
import ProjectsGrid from "../components/Projects/ProjectsGrid";

export default function ProjectsPage(props) {
  return (
    <div>
      <AppBar />
      <ProjectsGrid />
    </div>
  );
}
