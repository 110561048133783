import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import StoryboardWall from "./../Storyboards/StoryboardWall";
import Outliner from "./../Outliner/Outliner";

import StoryboardsOutliner from "./ProjectOutliner/Storyboards";
import BackgroundOutliner from "./ProjectOutliner/Backgrounds";
import PropOutliner from "./ProjectOutliner/Props";
import TeamOutliner from "./ProjectOutliner/Team";
import ProjectToolbox from "./ProjectOutliner/ProjectToolbox";
import { useProject, useProjectStoryboards } from "./hooks";
import { withRouter } from "react-router";
import { setRedirect } from "./../Utility/Redirect/hooks";
import StoryboardProperties from "./ProjectOutliner/StoryboardProperties";
import OutlinerDivider from "./../Outliner/OutlinerDivider";
import TeamManager from "../Users/TeamManager";
import OutlinerItem from "../Outliner/OutlinerItem";
import { Typography } from "@material-ui/core";
import BackgroundManager from "../Assets/Backgrounds/BackgroundManager";
import PropManager from "../Assets/Props/PropManager";
import CharacterManager from "../Assets/Characters/CharacterManager";
import EmptyProject from "./EmptyProject";

function ProjectPage(props) {
  const sessionIds = useSelector(state => state.session.sessionIds);
  const projectId = sessionIds ? sessionIds.projectId : null;
  const storyboardId = sessionIds ? sessionIds.storyboardId : null;

  const {
    value: storyboards,
    hasFetched: hasFetchedStoryboards,
    setDoFetch: setDoFetchStoryboards
  } = useProjectStoryboards(projectId);

  useEffect(() => {
    if (projectId) {
      setDoFetchStoryboards(true);
    }
  }, [projectId, setDoFetchStoryboards]);

  const {
    value: project,
    hasFetched: hasFetchedProject,
    setDoFetch: setDoFetchProject
  } = useProject(projectId);

  useEffect(() => {
    if (projectId) {
      setDoFetchProject(true);
    }
  }, [projectId, setDoFetchProject]);

  const storyboardsMap = storyboards.reduce((map, obj) => {
    map[obj.id] = obj;
    return map;
  }, {});

  const currentStoryboard = storyboardsMap[storyboardId];

  let redirectUrl = null;
  if (props.match.url === `/project/${projectId}` && hasFetchedProject) {
    if (!project) {
      redirectUrl = `/`;
    }
  }

  if (storyboardId && !projectId) {
    redirectUrl = `/`;
  } else if (
    props.match.url.startsWith("/storyboard/") &&
    hasFetchedStoryboards
  ) {
    if (!(storyboardId in storyboardsMap)) {
      if (projectId) {
        redirectUrl = `/project/${projectId}`;
      } else {
        redirectUrl = `/`;
      }
    } else if (currentStoryboard === undefined) {
      redirectUrl = `/`;
    }
  } else if (
    props.match.url === `/project/${projectId}` &&
    storyboards &&
    storyboards.length > 0
  ) {
    redirectUrl = `/storyboard/${storyboards[0].id}`;
  }

  if (redirectUrl) {
    setRedirect({
      to: redirectUrl,
      pushCurrentPage: false
    });
  }

  return (
    <div>
      <Outliner>
        <ProjectToolbox
          onStoryboardCreated={() => setDoFetchStoryboards(true)}
        />
        <OutlinerDivider major />
        <StoryboardsOutliner
          storyboards={storyboards}
          onUpdate={() => setDoFetchStoryboards(true)}
        />
        <OutlinerDivider major />
        <OutlinerItem selectable={false}>
          <Typography className="outliner-header">
            Project Management
          </Typography>
        </OutlinerItem>
        <BackgroundOutliner />
        <PropOutliner />
        <TeamOutliner />

        {currentStoryboard ? (
          <>
            <OutlinerDivider major />
            <StoryboardProperties
              storyboard={currentStoryboard}
              onUpdate={() => setDoFetchStoryboards(true)}
            />
          </>
        ) : null}
      </Outliner>
      <div className="outliner-partner-page">
        {storyboardId ? <StoryboardWall storyboardId={storyboardId} /> : null}
        {props.match.url === `/project/${projectId}/team` ? (
          <TeamManager projectId={projectId} />
        ) : null}
        {props.match.url === `/project/${projectId}/backgrounds` ? (
          <BackgroundManager projectId={projectId} />
        ) : null}
        {props.match.url === `/project/${projectId}/props` ? (
          <PropManager projectId={projectId} />
        ) : null}
        {props.match.url === `/project/${projectId}/characters` ? (
          <CharacterManager projectId={projectId} />
        ) : null}
        {props.match.url === `/project/${projectId}` &&
        hasFetchedStoryboards &&
        storyboards.length === 0 ? (
          <EmptyProject projectId={projectId} />
        ) : null}
      </div>
    </div>
  );
}

export default withRouter(ProjectPage);
