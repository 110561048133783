import React from "react";
import UploadDialog from "../Upload/UploadDialog";
import Backend from "../../../backend";

export default function BackgroundUploadDialog(props) {
  const { setOpen, projectId } = props;

  const onClose = files => {
    if (!files) {
      setOpen(false);
    } else {
      // Promise.all(
      //   Object.values(files).map(file => {
      //     return Backend.addPrivateBackground(
      //       projectId,
      //       { name: file.name },
      //       file
      //     );
      //   })
      // ).then(() => setOpen(false));
      const altImages = [];
      Object.values(files).forEach(file => {
        const [height, angle] = file.name
          .split(".")
          .slice(0, -1)
          .join(".")
          .split("-")
          .slice(-2);
        altImages.push({
          height: height,
          angle: parseInt(angle),
          file: file
        });
      });

      const defaultImage = altImages.filter(
        image => image.height === "normal" && image.angle === 0
      )[0].file;

      const name = defaultImage.name
        .split(".")
        .slice(0, -1)
        .join(".")
        .split("-")
        .slice(0, -2)
        .join("-");

      Backend.__addCharacter(
        projectId,
        { name: name },
        defaultImage,
        altImages
      ).then(() => setOpen(false));
    }
  };

  return (
    <UploadDialog {...props} title="Upload Backgrounds" onClose={onClose} />
  );
}
