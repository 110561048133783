import React, { useEffect } from "react";
import { useCharacters } from "../Characters/hooks";
import Gallery from "../Gallery/Gallery";
import "./CharacterGallery.scss";

export default function CharacterGallery(props) {
  const { projectId, onAssetClicked } = props;
  const { value: characters, setDoFetch } = useCharacters(projectId);

  useEffect(() => {
    if (projectId) {
      setDoFetch(true);
    }
  }, [projectId, setDoFetch]);

  return (
    <div className="character-gallery">
      <Gallery
        assets={characters}
        onAssetClicked={onAssetClicked}
        loadAssets={setDoFetch}
      />
    </div>
  );
}
