import React, { useEffect } from "react";

import { Select, InputLabel, MenuItem } from "@material-ui/core";
import "./properties.scss";

export default function DropdownProperty(props) {
  const { title, values, value, saveValue } = props;
  const [_value, _setValue] = React.useState(value);

  useEffect(() => {
    _setValue(value);
  }, [value]);

  useEffect(() => {
    if (value !== _value) {
      saveValue(_value);
    }
  }, [_value]);

  return (
    <div className="dropdown-property">
      <InputLabel id="label" shrink={true}>
        {title}
      </InputLabel>
      <Select
        value={_value}
        onChange={e => {
          _setValue(e.target.value);
        }}
        fullWidth={true}
        disableUnderline={true}
      >
        {values.map(v => {
          return (
            <MenuItem key={v} value={v}>
              {v || "<none>"}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
}
