import React, { useEffect } from "react";

import OutlinerItem from "./../../Outliner/OutlinerItem";

import { Typography } from "@material-ui/core";

import Backend from "../../../backend";

import TextProperty from "./../../Outliner/TextProperty";
import DropdownProperty from "./../../Outliner/DropdownProperty";
import Toolbox from "./../../Toolbox/Toolbox";
import ToolboxItem from "./../../Toolbox/ToolboxItem";
import ArchiveIcon from "@material-ui/icons/Delete";
import ToPdfIcon from "@material-ui/icons/PictureAsPdf";
import SlideshowIcon from "@material-ui/icons/Slideshow";
import { setRedirect } from "./../../Utility/Redirect/hooks";

function useFetchPdf(storyboardId) {
  const [shouldFetchPdf, setShouldFetchPdf] = React.useState(false);
  const [isFetchingPdf, setIsFetchingPdf] = React.useState(false);

  useEffect(() => {
    if (!shouldFetchPdf || isFetchingPdf || !storyboardId) {
      return;
    }

    setIsFetchingPdf(true);
    setShouldFetchPdf(false);
    Backend.downloadStoryboardPDF(storyboardId).then(() => {
      setShouldFetchPdf(false);
      setIsFetchingPdf(false);
    });
  }, [storyboardId, shouldFetchPdf, isFetchingPdf]);

  return [isFetchingPdf, setShouldFetchPdf];
}

export default function StoryboardProperties(props) {
  const { storyboard, onUpdate } = props;

  const [isFetchingPdf, setShouldFetchPdf] = useFetchPdf(storyboard.id);

  const formats = [
    { name: "16:9 (1920x1080)", width: 1920, height: 1080 },
    { name: "2.40:1 (1920x800)", width: 1920, height: 800 },
    { name: "4:3 (1440x1080)", width: 1440, height: 1080 }
  ];
  const formatLabels = formats.map(f => f.name);
  const currentFormatLabel =
    (storyboard.format && storyboard.format.name) || formats[0].name;

  const [archiveIP, setArchiveIP] = React.useState(false);
  const archiveStoryboard = storyboardId => {
    setArchiveIP(true);
    Backend.archiveStoryboard(storyboardId)
      .then(() => onUpdate())
      .finally(() => setArchiveIP(false));
  };

  return (
    <div className="properties">
      <OutlinerItem selectable={false}>
        <Typography className="outliner-header">
          Properties: <span className="highlight">Storyboard</span>
        </Typography>
      </OutlinerItem>
      <TextProperty
        title="Description"
        multiline
        value={storyboard.description}
        saveValue={value =>
          Backend.updateStoryboardDescription(storyboard.id, value).then(
            onUpdate()
          )
        }
      />
      <DropdownProperty
        title="Format"
        value={currentFormatLabel}
        values={formatLabels}
        saveValue={value => {
          const newFormat = formats.filter(f => f.name === value)[0];
          Backend.updateStoryboard(storyboard.id, {
            format: newFormat
          });
        }}
      />
      <Toolbox>
        <ToolboxItem
          tooltip="Slideshow"
          onClick={() =>
            setRedirect({ to: `/storyboard/${storyboard.id}/preview` })
          }
        >
          <SlideshowIcon />
        </ToolboxItem>
        <ToolboxItem
          tooltip="Download as a PDF"
          onClick={() => setShouldFetchPdf(true)}
          inProgress={isFetchingPdf}
        >
          <ToPdfIcon />
        </ToolboxItem>
        <ToolboxItem
          tooltip="Archive this storyboard"
          onClick={() => archiveStoryboard(storyboard.id)}
          inProgress={archiveIP}
        >
          <ArchiveIcon />
        </ToolboxItem>
      </Toolbox>
    </div>
  );
}
