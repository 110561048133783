const initialState = {
  selection: null,
  displayGridOfThirds: true,
  displayFrameMask: true,
  resetView: { shouldReset: false, zoom: 1.2 }
};

const frameEditor = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SELECTION":
      return {
        ...state,
        selection: action.id
      };

    case "SET_DISPLAY_GRID_OF_THIRDS":
      return {
        ...state,
        displayGridOfThirds: action.value
      };

    case "SET_DISPLAY_FRAME_MASK":
      return {
        ...state,
        displayFrameMask: action.value
      };

    case "RESET_VIEW":
      return {
        ...state,
        resetView: {
          shouldReset: action.shouldReset,
          zoom: action.value
        }
      };

    default:
      return state;
  }
};

export default frameEditor;
