import React from "react";

export default function OutlinerItem(props) {
  const { onClick, selected, selectable } = props;

  const selectableClass = selectable ? "selectable" : "";
  const selectedClass = selected ? "selected" : "";

  return (
    <div
      className={`outliner-item ${selectedClass} ${selectableClass}`}
      onClick={onClick}
    >
      {props.children}
    </div>
  );
}
