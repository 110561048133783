import React from "react";

import { Slider, InputLabel, Tooltip } from "@material-ui/core";
import ResetIcon from "@material-ui/icons/NotInterested";
import "./properties.scss";

export default function SliderProperty(props) {
  const {
    title,
    value,
    saveValue,
    min,
    max,
    step,
    marks,
    defaultValue
  } = props;

  return (
    <div className="slider-property">
      <InputLabel id="label" shrink={true}>
        {title}
      </InputLabel>
      <div>
        <Slider
          value={value !== undefined ? value : defaultValue}
          min={min}
          max={max}
          marks={marks}
          step={step}
          onChange={(e, v) => {
            saveValue(v);
          }}
        />
        <Tooltip title="Reset value" enterDelay={700} placement="right-start">
          <ResetIcon
            className="reset-icon"
            onClick={() => saveValue(defaultValue)}
          />
        </Tooltip>
      </div>
    </div>
  );
}
