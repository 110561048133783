import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Route, BrowserRouter, Redirect, Switch } from "react-router-dom";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { StylesProvider } from "@material-ui/styles";

import PrivateRoute from "./components/Utility/Auth/PrivateRoute";
import Loading from "./components/Utility/Loading/Loading";
import RedirectHelper from "./components/Utility/Redirect/Redirect";

import SignInPage from "./screens/SignIn";
import SignOutPage from "./screens/SignOut";
import ProjectsPage from "./screens/Projects";
import ProjectPage from "./screens/Project";
import FrameEditorPage from "./screens/FrameEditor";
import FramePreviewPage from "./screens/FramePreview";
import AdminProjectsPage from "./screens/AdminProjects";
import AdminFramesPage from "./screens/AdminFrames";
import StoryboardPdfPage from "./screens/StoryboardPdf";
import StoryboardPreviewPage from "./screens/StoryboardPreview";

import store from "./store";
import "./index.scss";
import SegmentScript from "./segment/SegmentScript";
import SegmentBrowser from "./segment/SegmentBrowser";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#f8b133" }
  }
});

ReactDOM.render(
  <StylesProvider injectFirst>
    <SegmentScript />
    <Provider store={store}>
      <BrowserRouter>
        <SegmentBrowser />
        <MuiThemeProvider theme={theme}>
          <Loading />
          <RedirectHelper />
          <div>
            <Switch>
              <Route path="/signin" component={SignInPage} />
              <Route path="/signout" component={SignOutPage} />
              <Route
                exact
                path="/"
                render={() => <Redirect to="/projects" push={true} />}
              />
              <PrivateRoute exact path="/projects" component={ProjectsPage} />
              <PrivateRoute
                exact
                path="/project/:projectId"
                component={ProjectPage}
              />
              <PrivateRoute
                exact
                path="/project/:projectId/backgrounds"
                component={ProjectPage}
              />
              <PrivateRoute
                exact
                path="/project/:projectId/props"
                component={ProjectPage}
              />
              <PrivateRoute
                exact
                path="/project/:projectId/characters"
                component={ProjectPage}
              />
              <PrivateRoute
                exact
                path="/project/:projectId/team"
                component={ProjectPage}
              />
              <Route
                exact
                path="/storyboard/:storyboardId/pdf"
                component={StoryboardPdfPage}
              />
              {/* TODO: Make private */}
              <Route
                exact
                path="/storyboard/:storyboardId"
                component={ProjectPage}
              />
              <Route
                exact
                path="/storyboard/:storyboardId/preview"
                component={StoryboardPreviewPage}
              />
              <PrivateRoute
                exact
                path="/frame/:frameId"
                component={FrameEditorPage}
              />
              <PrivateRoute
                exact
                path="/admin/projects"
                component={AdminProjectsPage}
              />
              <PrivateRoute
                exact
                path="/admin/frames"
                component={AdminFramesPage}
              />
              {/* TODO: Make private */}
              <Route
                exact
                path="/frame/:frameId/preview"
                component={FramePreviewPage}
              />
              <Redirect to="/" />
            </Switch>
          </div>
        </MuiThemeProvider>
      </BrowserRouter>
    </Provider>
  </StylesProvider>,
  document.getElementById("root")
);
