import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import TextProperty from "../../Outliner/TextProperty";
import Backend from "../../../backend";
import Toolbox from "../../Toolbox/Toolbox";
import ToolboxItem from "../../Toolbox/ToolboxItem";
import DeleteIcon from "@material-ui/icons/Delete";
import OutlinerItem from "../../Outliner/OutlinerItem";
import { Typography } from "@material-ui/core";
import { useFrame } from "./../hooks";
import { setRedirect } from "./../../Utility/Redirect/hooks";
import DropdownProperty from "../../Outliner/DropdownProperty";

export default function FrameProperties(props) {
  const sessionIds = useSelector(state => state.session.sessionIds);
  const frameId = sessionIds ? sessionIds.frameId : null;
  const { value: frame, setDoFetch: fetchFrame } = useFrame(frameId);
  const fdl = useSelector(state => state.fdl.present);

  useEffect(() => fetchFrame(true), [frameId, fetchFrame]);

  const [archiveIP, setArchiveIP] = React.useState(false);
  const archiveFrame = frameId => {
    setArchiveIP(true);
    Backend.archiveFrame(frameId)
      .then(() => setRedirect({ to: `/storyboard/${frame.storyboardId}` }))
      .finally(() => setArchiveIP(false));
  };

  const dispatch = useDispatch();

  const cameraMoves = [
    "",
    "Pan Left",
    "Pan Right",
    "Tilt Up",
    "Tilt Down",
    "Truck Left",
    "Truck Right",
    "Pedestal Up",
    "Pedestal Down",
    "Zoom In",
    "Zoom Out",
    "Dolly In",
    "Dolly Out"
  ];

  return frame ? (
    <>
      <OutlinerItem selectable={false}>
        <Typography className="outliner-header">
          Properties: <span className="highlight">Frame</span>
        </Typography>
      </OutlinerItem>
      <TextProperty
        title="Title"
        value={frame.name || ""}
        saveValue={value => {
          Backend.updateFrameName(frameId, value);
        }}
      />
      <TextProperty
        title="Description"
        value={frame.description || ""}
        multiline
        saveValue={value => {
          Backend.updateFrameDescription(frameId, value);
        }}
      />
      <DropdownProperty
        title="Camera Move"
        value={(fdl && fdl.cameraMove) || ""}
        values={cameraMoves}
        saveValue={value => {
          dispatch({
            type: "UPDATE_CAMERA_MOVE",
            cameraMove: value,
            frameId: frameId
          });
        }}
      />
      <Toolbox>
        <ToolboxItem
          tooltip="Delete this frame"
          onClick={() => archiveFrame(frameId)}
          inProgress={archiveIP}
        >
          <DeleteIcon />
        </ToolboxItem>
      </Toolbox>
    </>
  ) : null;
}
