const IGNORE_TYPES = ["SET_REDIRECT"];

function sessionInfo(state) {
  const sessionUser = state.session.sessionUser;
  const sessionIds = state.session.sessionIds;
  return {
    user: {
      uid: sessionUser.user && sessionUser.user.uid,
      email: sessionUser.user && sessionUser.user.email
    },
    projectId: sessionIds && sessionIds.projectId,
    storyboardId: sessionIds && sessionIds.storyboardId,
    frameId: sessionIds && sessionIds.frameId
  };
}

function segmentMiddleware({ getState }) {
  return next => action => {
    if (!IGNORE_TYPES.includes(action.type)) {
      const state = getState();
      const data = { ...action };
      delete data.type;
      if (window.analytics) {
        window.analytics.track(action.type, {
          session: sessionInfo(state),
          data: data
        });
      }
    }

    const returnValue = next(action);
    return returnValue;
  };
}
export default segmentMiddleware;
