import React from "react";
import { Drawer } from "@material-ui/core";
import "./Outliner.scss";

export default function Outliner(props) {
  return (
    <Drawer className="outliner" variant="permanent">
      {props.children}
    </Drawer>
  );
}
