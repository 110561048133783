import React from "react";
import { useSelector } from "react-redux";
import { ButtonBase, Avatar, Menu, MenuItem, Divider } from "@material-ui/core";
import { setRedirect } from "./../Utility/Redirect/hooks";
import "./ProfileButton.scss";

function ProfileButton(props) {
  const session = useSelector(state => state.session.sessionUser);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleAvatarClick = event => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  return (
    <div className="app-bar-profile-button">
      {session.isLoggedIn ? (
        <div>
          <ButtonBase onClick={handleAvatarClick}>
            <Avatar
              alt={session.user.displayName || session.user.email}
              src={session.user.photoURL || null}
            >
              {!session.user.photoURL &&
                (session.user.displayName || session.user.email || "?")
                  .charAt(0)
                  .toUpperCase()}
            </Avatar>
          </ButtonBase>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            keepMounted
          >
            <MenuItem disabled>Profile</MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                setRedirect({ to: "/signout" });
              }}
            >
              Logout
            </MenuItem>
          </Menu>
        </div>
      ) : (
        <div className="dummy-button-slot" />
      )}
    </div>
  );
}

export default React.memo(ProfileButton);
