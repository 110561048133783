import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import HomeButton from "./HomeButton";
import HelpButton from "./HelpButton";
import ProfileButton from "./ProfileButton";
import BreadcrumbsTitle from "./BreadcrumbsTitle";
import "./AppBar.scss";

export default function AppBar_(props) {
  const { title, withHelp } = props;
  return (
    <div>
      <AppBar className="app-bar">
        <Toolbar className="tool-bar" disableGutters>
          <HomeButton />
          {withHelp ? <HelpButton /> : <div className="dummy-button-slot" />}
          <div className="dummy-button-slot" />

          <BreadcrumbsTitle title={title} />

          <div className="dummy-button-slot" />
          <div className="dummy-button-slot" />
          <ProfileButton />
        </Toolbar>
      </AppBar>

      {/* This helps maintain offsets & positioning by having two components with positions sticky & fixed */}
      <AppBar className="app-bar-dummy" />
    </div>
  );
}
