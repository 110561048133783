import React from "react";
import { useSelector } from "react-redux";
import CharacterProperties from "./CharacterProperties";
import BackgroundProperties from "./BackgroundProperties";
import PropProperties from "./PropProperties";
import FrameProperties from "./FrameProperties";
import OutlinerDivider from "./../../Outliner/OutlinerDivider";

export default function Properties(props) {
  const fdl = useSelector(state => state.fdl.present);
  const frameEditor = useSelector(state => state.frameEditor);

  const selection = frameEditor.selection;

  //   const dispatch = useDispatch();

  const characters = fdl.characters || {};

  let backgrounds;
  if (fdl.backgrounds) {
    backgrounds = fdl.backgrounds;
  } else if (fdl.background && Object.keys(fdl.background).length > 0) {
    backgrounds = { bg1: fdl.background };
  } else {
    backgrounds = {};
  }

  let propAssets;
  if (fdl.props) {
    propAssets = fdl.props;
  } else if (fdl.prop && Object.keys(fdl.prop).length > 0) {
    propAssets = { bg1: fdl.prop };
  } else {
    propAssets = {};
  }

  return (
    <>
      {selection && selection in characters ? (
        <>
          <OutlinerDivider major />
          <CharacterProperties id={selection} />
        </>
      ) : null}

      {selection && selection in backgrounds ? (
        <>
          <OutlinerDivider major />
          <BackgroundProperties id={selection} />
        </>
      ) : null}

      {selection && selection in propAssets ? (
        <>
          <OutlinerDivider major />
          <PropProperties id={selection} />
        </>
      ) : null}
      {!selection ? (
        <>
          <OutlinerDivider major />
          <FrameProperties />
        </>
      ) : null}
    </>
  );
}

// export default withRouter(AssetOutliner);
