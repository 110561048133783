import React from "react";
import { useDispatch, useSelector } from "react-redux";
import FrameCanvas from "../components/Frame/FrameCanvas";
import { useInitSessionIdsFromParams } from "../components/Utility/Session/hooks";
import { useFDLWithoutSync } from "./../components/Frame/hooks";

export default function FramePreviewPage(props) {
  useInitSessionIdsFromParams(props);

  const sessionIds = useSelector(state => state.session.sessionIds);
  const frameId = sessionIds ? sessionIds.frameId : null;

  useFDLWithoutSync(frameId);

  const dispatch = useDispatch();

  dispatch({ type: "SET_DISPLAY_GRID_OF_THIRDS", value: false });
  dispatch({ type: "SET_DISPLAY_FRAME_MASK", value: true });

  return (
    <div>
      <FrameCanvas noSideBar={true} noAppBar={true} initialZoom={1.0} />
    </div>
  );
}
