import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useInitSessionIdsFromParams } from "../components/Utility/Session/hooks";
import { useFrames } from "../components/Storyboards/hooks";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./StoryboardPreview.scss";
import { Typography } from "@material-ui/core";
import AppBar from "../components/AppBar/AppBar";

export default function StoryboardPreview(props) {
  useInitSessionIdsFromParams(props);

  const sessionIds = useSelector(state => state.session.sessionIds);
  const projectId = sessionIds ? sessionIds.projectId : null;
  const storyboardId = sessionIds ? sessionIds.storyboardId : null;

  const { frames, orderedFrameIds } = useFrames(storyboardId);

  const thumbnails = orderedFrameIds
    .filter(id => frames[id].thumbnail)
    .map(id => {
      const frame = frames[id];
      const thumbnailGenerated = frame.thumbnailTimestamp;
      const frameUpdated = frame.fdl && frame.fdl.timestamp;
      const isOutOfDate =
        frameUpdated && frameUpdated > thumbnailGenerated ? false : true;
      return {
        original: frame.thumbnail,
        thumbnail: frame.thumbnail,
        description: frame.fdl.description,
        id: id,
        key: id,
        isOutOfDate: isOutOfDate
      };
    });

  const [currentIdx, setCurrentIdx] = React.useState(0);
  const [currentFrame, setCurrentFrame] = React.useState(null);
  useEffect(() => {
    if (currentIdx !== null && thumbnails.length > 0) {
      setCurrentFrame(frames[thumbnails[currentIdx].id]);
    } else {
      setCurrentFrame(null);
    }
  }, [currentIdx, frames, thumbnails]);

  useEffect(() => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    window.addEventListener("resize", e => {
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  }, []);

  const currentUrl = window.location.href;

  return storyboardId && projectId ? (
    <div className="storyboard-preview-page">
      <AppBar withHelp={false} />

      <div className="storyboard-preview-page-container">
        <Typography variant="h6" className="frame-title">
          {currentFrame ? currentFrame.name : " "}
        </Typography>
        <ImageGallery
          items={thumbnails}
          infinite={false}
          onSlide={idx => setCurrentIdx(idx)}
          onBeforeSlide={idx => setCurrentIdx(null)}
          showFullscreenButton={false}
        />
        <Typography variant="body1" className="frame-description">
          {currentFrame ? currentFrame.description : " "}
        </Typography>

        <div className="share-container">
          <Typography variant="body1">{currentUrl}</Typography>
          <button
            onClick={() => {
              const el = document.createElement("textarea");
              el.value = currentUrl;
              document.body.appendChild(el);
              el.select();
              document.execCommand("copy");
              document.body.removeChild(el);
            }}
          >
            Click here to copy url to share
          </button>
        </div>
      </div>
    </div>
  ) : (
    <div />
  );
}
