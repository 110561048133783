import React from "react";
import Typography from "@material-ui/core/Typography";
import "./BreadcrumbsTitle.scss";

//TODO: Breadcrumbs
export default function BreadcrumbsTitle(props) {
  return (
    <Typography className="breadcrumbs-title" variant="h6">
      {props.title !== undefined ? props.title : "Viso Labs"}
    </Typography>
  );
}
