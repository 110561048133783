import React from "react";

import { useSelector } from "react-redux";
import OutlinerItem from "./../../Outliner/OutlinerItem";
import OutlinerInstanceText from "./../../Outliner/OutlinerInstanceText";
import StoryboardIcon from "@material-ui/icons/Panorama";

import { Typography } from "@material-ui/core";

import { setRedirect } from "./../../Utility/Redirect/hooks";
import Backend from "./../../../backend";

export default function StoryboardsOutliner(props) {
  const { storyboards, onUpdate } = props;

  const sessionIds = useSelector(state => state.session.sessionIds);
  const sessionStoryboardId = sessionIds ? sessionIds.storyboardId : null;

  return (
    <div>
      <OutlinerItem selectable={false}>
        <Typography className="outliner-header">Storyboards</Typography>
      </OutlinerItem>

      {storyboards.map(storyboard => {
        return (
          <OutlinerItem
            key={storyboard.id}
            onClick={() => {
              setRedirect({ to: `/storyboard/${storyboard.id}` });
            }}
            selected={storyboard.id === sessionStoryboardId}
            selectable={true}
          >
            <StoryboardIcon />
            <OutlinerInstanceText
              value={storyboard.name}
              saveValue={val =>
                Backend.updateStoryboardName(storyboard.id, val).then(() =>
                  onUpdate()
                )
              }
              selected={storyboard.id === sessionStoryboardId}
            />
          </OutlinerItem>
        );
      })}
    </div>
  );
}
