const initialState = {
  loading: false,
  redirect: {}
};

const page = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PAGE_LOADING":
      return {
        ...state,
        loading: action.loading
      };

    case "SET_REDIRECT":
      if (action.redirect === state.redirect) {
        return state;
      } else if (!action.redirect) {
        return {
          ...state,
          redirect: {}
        };
      } else if (action.redirect.to !== state.redirect.to) {
        return {
          ...state,
          redirect: action.redirect
        };
      } else {
        return state;
      }

    default:
      return state;
  }
};

export default page;
