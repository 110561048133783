import React from "react";
import { Typography, CircularProgress } from "@material-ui/core";
import Backend from "./../../backend";
import "./FrameCard.scss";

export default function NewFrameCard(props) {
  const { storyboardId, onNewFrameCreated } = props;
  const [inProgress, setInProgress] = React.useState(false);

  return (
    <div
      className="frame-card new-frame-card"
      onClick={() => {
        if (inProgress) {
          return;
        }
        setInProgress(true);
        Backend.createFrame(storyboardId)
          .then(frameId => !inProgress && onNewFrameCreated(frameId))
          .finally(() => setInProgress(false));
      }}
    >
      <div className="image-container">
        <div className="title-container">
          {!inProgress ? (
            <Typography
              className="title unselectable"
              variant="h5"
              justify="center"
            >
              + New Frame
            </Typography>
          ) : (
            <CircularProgress className="new-frame-progress" />
          )}
        </div>
      </div>
    </div>
  );
}
