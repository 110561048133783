import React from "react";
import { useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import "./Loading.scss";

//TODO: Make a nice loading indicator
function Loading(props) {
  const loading = useSelector(state => state.page.loading);
  return (
    <div>
      {loading ? (
        <div className="loading-container">
          <Typography variant="h6"> Loading... </Typography>
        </div>
      ) : null}
    </div>
  );
}

export default Loading;
