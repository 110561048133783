import React from "react";
import AppBar from "../components/AppBar/AppBar";
import FrameEditor from "./../components/Frame/FrameEditor";
import FrameEditorHelp from "./../components/Frame/FrameEditorHelp";
import { useInitSessionIdsFromParams } from "./../components/Utility/Session/hooks";

export default function FrameEditorPage(props) {
  useInitSessionIdsFromParams(props);

  return (
    <div>
      <AppBar withHelp={true} />
      <FrameEditor />
      <FrameEditorHelp />
    </div>
  );
}
