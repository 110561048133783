import React from "react";
import "./FrameCardActions.scss";

import CopyIcon from "@material-ui/icons/FileCopy";
import DeleteIcon from "@material-ui/icons/Delete";
import { Tooltip } from "@material-ui/core";
import Backend from "../../backend";

export default function FrameCardActions(props) {
  const { frameId, onActionComplete } = props;
  return (
    <div className="actions">
      <Tooltip title="Duplicate frame" placement="right-start">
        <CopyIcon
          className="action-icon"
          onClick={() => {
            let relativeToFrameId = frameId;
            Backend.duplicateFrame(frameId, relativeToFrameId)
              .then(() => {
                return onActionComplete();
              })
              .finally(() => (document.body.style.cursor = "default"));
          }}
        />
      </Tooltip>

      <Tooltip title="Delete frame" placement="right-start">
        <DeleteIcon
          className="action-icon"
          onClick={() => {
            document.body.style.cursor = "wait";
            Backend.archiveFrame(frameId)
              .then(() => {
                return onActionComplete();
              })
              .finally(() => (document.body.style.cursor = "default"));
          }}
        />
      </Tooltip>
    </div>
  );
}
