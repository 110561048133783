import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import AddBackgroundIcon from "@material-ui/icons/AddPhotoAlternate";
import AddPropIcon from "@material-ui/icons/CardTravel";
import AddCharacterIcon from "@material-ui/icons/PersonAdd";
import UndoIcon from "@material-ui/icons/Undo";
import RedoIcon from "@material-ui/icons/Redo";
import GridOnIcon from "@material-ui/icons/GridOn";
import GridOffIcon from "@material-ui/icons/GridOff";
import MaskOnIcon from "@material-ui/icons/FeaturedVideo";
import MaskOffIcon from "@material-ui/icons/FeaturedVideoOutlined";
import ClearSelectionIcon from "@material-ui/icons/Clear";
import ResetViewIcon from "@material-ui/icons/CropFree";
import FrameReelIcon from "@material-ui/icons/TheatersOutlined";

import { ActionCreators } from "redux-undo";

import Toolbox from "./../../Toolbox/Toolbox";
import ToolboxItem from "./../../Toolbox/ToolboxItem";

import SideDialog from "./../../Utility/SideDialog/SideDialog";
import BackgroundMultiGallery from "../../Assets/Backgrounds/BackgroundMultiGallery";
import PropMultiGallery from "../../Assets/Props/PropMultiGallery";
import CharacterGallery from "./../../Assets/Characters/CharacterGallery";
import FrameReelDialog from "./../../FrameReel/FrameReelDialog";

function UndoTool(props) {
  const dispatch = useDispatch();
  return (
    <ToolboxItem tooltip="Undo">
      <UndoIcon
        onClick={() => {
          dispatch(ActionCreators.undo());
        }}
      />
    </ToolboxItem>
  );
}

function RedoTool(props) {
  const dispatch = useDispatch();
  return (
    <ToolboxItem tooltip="Redo">
      <RedoIcon
        onClick={() => {
          dispatch(ActionCreators.redo());
        }}
      />
    </ToolboxItem>
  );
}

function AddCharacterTool(props) {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(null);

  useEffect(() => {
    if (selected && selected.id && isOpen) {
      const charId = Math.random()
        .toString(36)
        .substring(9);

      dispatch({
        type: "ADD_CHARACTER",
        charId: charId,
        character: selected
      });
      setSelected(null);
      setIsOpen(false);
    }
  }, [selected, dispatch, isOpen]);

  return (
    <ToolboxItem tooltip="Add a character">
      <SideDialog
        title={"Add a Character"}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        isExpanded={false}
      >
        <CharacterGallery projectId={"public"} onAssetClicked={setSelected} />
      </SideDialog>

      <AddCharacterIcon
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      />
    </ToolboxItem>
  );
}

function DisplayGridTool(props) {
  const dispatch = useDispatch();
  const value = useSelector(state => state.frameEditor.displayGridOfThirds);

  return (
    <ToolboxItem tooltip="Toggle grid">
      {value ? (
        <GridOnIcon
          onClick={() => {
            dispatch({ type: "SET_DISPLAY_GRID_OF_THIRDS", value: false });
          }}
        />
      ) : (
        <GridOffIcon
          onClick={() => {
            dispatch({ type: "SET_DISPLAY_GRID_OF_THIRDS", value: true });
          }}
        />
      )}
    </ToolboxItem>
  );
}

function DisplayMaskTool(props) {
  const dispatch = useDispatch();
  const value = useSelector(state => state.frameEditor.displayFrameMask);

  return (
    <ToolboxItem tooltip="Toggle frame mask">
      {value ? (
        <MaskOnIcon
          onClick={() => {
            dispatch({ type: "SET_DISPLAY_FRAME_MASK", value: false });
          }}
        />
      ) : (
        <MaskOffIcon
          onClick={() => {
            dispatch({ type: "SET_DISPLAY_FRAME_MASK", value: true });
          }}
        />
      )}
    </ToolboxItem>
  );
}

function ResetViewTool(props) {
  const dispatch = useDispatch();

  return (
    <ToolboxItem tooltip="Reset view">
      <ResetViewIcon
        onClick={() => {
          dispatch({ type: "RESET_VIEW", shouldReset: true, value: 1.2 });
        }}
      />
    </ToolboxItem>
  );
}

function ClearSelectionTool(props) {
  const dispatch = useDispatch();
  return (
    <ToolboxItem tooltip="Clear selection">
      <ClearSelectionIcon
        onClick={() => {
          dispatch({ type: "SET_SELECTION", id: null });
        }}
      />
    </ToolboxItem>
  );
}

function FrameReelTool(props) {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <ToolboxItem tooltip="Toggle frame reel">
      <FrameReelDialog isOpen={isOpen} setIsOpen={setIsOpen}></FrameReelDialog>
      <FrameReelIcon
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      />
    </ToolboxItem>
  );
}

function AddBackgroundTool(props) {
  const sessionIds = useSelector(state => state.session.sessionIds);
  const projectId = (sessionIds && sessionIds.projectId) || null;
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(null);

  useEffect(() => {
    if (selected && selected.id && isOpen) {
      const backgroundId = Math.random()
        .toString(36)
        .substring(9);

      dispatch({
        type: "ADD_BACKGROUND",
        backgroundId: backgroundId,
        background: selected
      });
      setSelected(null);
      setIsOpen(false);
    }
  }, [selected, dispatch, isOpen]);

  return (
    <ToolboxItem tooltip="Add a background">
      <SideDialog
        title={"Add a Background"}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        isExpanded={false}
      >
        <BackgroundMultiGallery
          projectId={projectId}
          onAssetClicked={setSelected}
        />
      </SideDialog>

      <AddBackgroundIcon
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      />
    </ToolboxItem>
  );
}

function AddPropTool(props) {
  const sessionIds = useSelector(state => state.session.sessionIds);
  const projectId = (sessionIds && sessionIds.projectId) || null;
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(null);

  useEffect(() => {
    if (selected && selected.id && isOpen) {
      const propId = Math.random()
        .toString(36)
        .substring(9);

      dispatch({
        type: "ADD_PROP",
        propId: propId,
        prop: selected
      });
      setSelected(null);
      setIsOpen(false);
    }
  }, [selected, dispatch, isOpen]);

  return (
    <ToolboxItem tooltip="Add a prop">
      <SideDialog
        title={"Add a Prop"}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        isExpanded={false}
      >
        <PropMultiGallery projectId={projectId} onAssetClicked={setSelected} />
      </SideDialog>

      <AddPropIcon
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      />
    </ToolboxItem>
  );
}

export default function FrameToolbox() {
  return (
    <Toolbox>
      <UndoTool />
      <RedoTool />
      <AddCharacterTool />
      <AddBackgroundTool />
      <AddPropTool />
      <DisplayGridTool />
      <DisplayMaskTool />
      <ResetViewTool />
      <ClearSelectionTool />
      <FrameReelTool />
    </Toolbox>
  );
}
