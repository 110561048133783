import React from "react";
import { Redirect } from "react-router-dom";
import { Typography } from "@material-ui/core";
import AppBar from "./../components/AppBar/AppBar";
import VisoLabsPic from "./../assets/images/Visolabs.png";
import FirebaseAuth from "./../components/Utility/Auth/FirebaseAuth";
import { useCheckUserValid } from "./../components/Utility/Auth/hooks";
import "./SignIn.scss";

// TODO: Tidy up styling
export default function SignInPage(props) {
  const { isUserValid } = useCheckUserValid();

  const fromUrl = props.location.state
    ? props.location.state.from.pathname
    : "/";

  return isUserValid ? (
    <Redirect
      to={{
        pathname: props.location.state
          ? props.location.state.from.pathname
          : "/"
      }}
    />
  ) : (
    <div>
      <AppBar title="" />
      <div className="signin-layout">
        <img className="viso-logo" src={VisoLabsPic} alt="" />
        <Typography variant="body1">
          Welcome to our Storyboard Alpha!
        </Typography>
        <Typography variant="body1">
          Please create an account or sign in to continue
        </Typography>
        <FirebaseAuth fromUrl={fromUrl} />
      </div>
    </div>
  );
}
