import React from "react";
import HelpIcon from "@material-ui/icons/HelpOutline";
import { ButtonBase } from "@material-ui/core";
import "./HelpButton.scss";
import { useSelector } from "react-redux";
import store from "./../../store";

export default function HelpButton(props) {
  const isOpen = useSelector(state => state.ui.help.open);
  return (
    <ButtonBase
      className="app-bar-help-button"
      onClick={() => store.dispatch({ type: "SET_HELP_OPEN", open: !isOpen })}
    >
      <HelpIcon />
    </ButtonBase>
  );
}
