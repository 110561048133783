import React from "react";
import { setRedirect } from "./../Redirect/hooks";
import { StyledFirebaseAuth } from "react-firebaseui";
import Backend from "./../../../backend";
import Firebase from "./../../../firebase";

const firebase = new Firebase();

export default function FirebaseAuth(props) {
  const { fromUrl } = props;

  // Configure FirebaseUI.
  const uiConfig = {
    signInFlow: "popup",
    callbacks: {
      signInSuccessWithAuthResult: (authResult, redirectUrl) => {
        Backend.updateCurrentUserInfo().then(() => {
          setRedirect({ to: fromUrl || "/" });
        });
        return false;
      }
    },
    signInOptions: [
      firebase.app.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.app.auth.EmailAuthProvider.PROVIDER_ID
    ]
  };

  return (
    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth} />
  );
}
