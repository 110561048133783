import React from "react";
import { useSelector } from "react-redux";
import {
  Dialog,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell
} from "@material-ui/core";
import store from "./../../store";
import "./FrameEditorHelp.scss";

export default function FrameEditorHelp(props) {
  const isOpen = useSelector(state => state.ui.help.open);
  return (
    <Dialog
      className="frame-editor-help-dialog"
      open={isOpen}
      onClose={() => store.dispatch({ type: "SET_HELP_OPEN", open: false })}
    >
      <div className="frame-editor-help-layout">
        <Typography variant="h5">Editor Controls</Typography>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Pan Screen</TableCell>
              <TableCell>Click + Drag with mouse or touchscreen</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Zoom In/Out</TableCell>
              <TableCell>
                Scroll with mouse wheel or Pinch with two fingers
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </Dialog>
  );
}
