import React from "react";
import { Redirect } from "react-router-dom";
import { useRedirect } from "./hooks";
import { withRouter } from "react-router";

function RedirectHelper(props) {
  const { redirect, setRedirect } = useRedirect();

  let performRedirect = redirect && redirect.to;

  if (performRedirect) {
    if (redirect.to === props.location.pathname) {
      setRedirect(null);
      performRedirect = false;
    }
  }

  return performRedirect ? (
    <Redirect
      to={{ pathname: redirect.to, state: { from: props.location } }}
      push={
        redirect.hasOwnProperty("pushCurrentPage")
          ? redirect.pushCurrentPage
          : true
      }
    />
  ) : null;
}

export default withRouter(RedirectHelper);
