// import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import store from "./../../../store";
import Backend from "./../../../backend";

// TODO: Store project, storyboard, frame data here as listeners ???

export function useInitSessionIdsFromParams(props) {
  let sessionIds = useSelector(state => state.session.sessionIds);
  if (!sessionIds) {
    sessionIds = { projectId: null, storyboardId: null, frameId: null };
  }

  const params = props && props.match ? props.match.params : {};

  useEffect(() => {
    if (params.frameId) {
      if (params.frameId === sessionIds.frameId) {
        return;
      } else {
        Backend.getFrame(params.frameId).then(frame => {
          store.dispatch({
            type: "SET_SESSION_IDS",
            projectId: frame.projectId,
            storyboardId: frame.storyboardId,
            frameId: params.frameId
          });
        });
      }
    } else if (params.storyboardId) {
      if (params.storyboardId === sessionIds.storyboardId) {
        if (sessionIds.frameId) {
          store.dispatch({
            type: "SET_SESSION_IDS",
            projectId: sessionIds.projectId,
            storyboardId: params.storyboardId,
            frameId: null
          });
        } else {
          return;
        }
      } else {
        Backend.getStoryboard(params.storyboardId).then(storyboard => {
          store.dispatch({
            type: "SET_SESSION_IDS",
            projectId: storyboard.projectId,
            storyboardId: params.storyboardId,
            frameId: null
          });
        });
      }
    } else if (params.projectId) {
      if (params.projectId === sessionIds.projectId) {
        if (sessionIds.storyboardId || sessionIds.frameId) {
          store.dispatch({
            type: "SET_SESSION_IDS",
            projectId: params.projectId,
            storyboardId: null,
            frameId: null
          });
        } else {
          return;
        }
      } else {
        store.dispatch({
          type: "SET_SESSION_IDS",
          projectId: params.projectId,
          storyboardId: null,
          frameId: null
        });
      }
    }
  }, [params, sessionIds]);
}
