import React, { useEffect } from "react";
import { Typography, TextField, Tooltip } from "@material-ui/core";
import Backend from "./../../backend";
import DropdownProperty from "./../Outliner/DropdownProperty";

import "./EmptyProject.scss";
import "./../Toolbox/Toolbox.scss";
import { setRedirect } from "../Utility/Redirect/hooks";

export default function EmtpyProject(props) {
  const { projectId } = props;

  const nameInputRef = React.createRef();

  const formats = [
    { name: "16:9 (1920x1080)", width: 1920, height: 1080 },
    { name: "2.40:1 (1920x800)", width: 1920, height: 800 },
    { name: "4:3 (1440x1080)", width: 1440, height: 1080 }
  ];
  const formatLabels = formats.map(f => f.name);

  const [name, setName] = React.useState("New Storyboard");
  const [description, setDescription] = React.useState("");
  const [format, setFormat] = React.useState(formats[0]);

  useEffect(() => {
    if (nameInputRef.current) {
      nameInputRef.current.select();
    }
  }, []);

  return (
    <div className="empty-project-layout">
      <Typography className="title" variant="h5">
        Welcome to your new project!
      </Typography>
      <div className="text MuiTypography-body1">
        From here, you can:
        <ul>
          <li>
            Create and edit <b>storyboards</b> and <b>frames</b>
          </li>
          <li>Manage your projects private background images</li>
          <li>Share access to your project with other users</li>
        </ul>
        To get started, create a new storyboard below.
      </div>
      <div className="new-storyboard-card">
        <Typography variant="h6"> New Storyboard</Typography>
        <div>
          <TextField
            variant="standard"
            margin="dense"
            InputProps={{ disableUnderline: true }}
            InputLabelProps={{
              shrink: true,
              focused: false
            }}
            inputRef={nameInputRef}
            fullWidth={false}
            label="Title"
            value={name}
            onChange={e => setName(e.target.value)}
            autoFocus={true}
          />
          <TextField
            variant="standard"
            margin="dense"
            InputProps={{ disableUnderline: true }}
            InputLabelProps={{
              shrink: true,
              focused: false
            }}
            fullWidth={false}
            placeholder={`Enter description`}
            label="Description (optional)"
            value={description}
            multiline={true}
            inputProps={{ rows: 4 }}
            onChange={e => setDescription(e.target.value)}
          />
          <DropdownProperty
            title="Format"
            value={format.name}
            values={formatLabels}
            saveValue={value => {
              setFormat(formats.filter(f => f.name === value)[0]);
            }}
          />

          <div className="create-storyboard-button">
            <Tooltip
              title="Click to create a new storyboard"
              placement="right-start"
            >
              <button
                onClick={() => {
                  if (projectId) {
                    const data = {
                      name: name,
                      description: description,
                      format: format
                    };
                    Backend.createStoryboard(projectId, data)
                      .then(storyboardId => {
                        return Backend.createFrame(storyboardId);
                      })
                      .then(frameId =>
                        setRedirect({ to: `/frame/${frameId}` })
                      );
                  }
                }}
              >
                <Typography>Create Storyboard</Typography>
              </button>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
}
