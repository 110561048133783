import React, { useEffect } from "react";

import { TextField } from "@material-ui/core";
import "./properties.scss";

export default function TextProperty(props) {
  const { title, multiline, value, saveValue, isValueValid } = props;

  const [_value, _setValue] = React.useState(value);

  function _saveValue(name) {
    return saveValue(name);
  }

  function validateAndSetValue(value) {
    let isValid = true;
    if (isValueValid !== undefined) {
      try {
        isValid = isValueValid(value);
      } catch {
        isValid = false;
      }
    }
    if (isValid) {
      _setValue(value);
    }
  }

  useEffect(() => {
    validateAndSetValue(value);
  }, [value]);

  return (
    <div className="text-property">
      <TextField
        variant="standard"
        margin="dense"
        InputProps={{ disableUnderline: true }}
        InputLabelProps={{
          shrink: true,
          focused: false
        }}
        multiline={multiline}
        fullWidth={true}
        inputProps={{ rows: 2 }}
        placeholder={`Enter ${title.toLowerCase()} ...`}
        label={title}
        value={_value}
        onBlur={() => saveValue(_value)}
        onChange={e => validateAndSetValue(e.target.value)}
        onKeyPress={e => (e.key === "Enter" ? _saveValue(_value) : null)}
      />
    </div>
  );
}
