import React from "react";
import VisoLabsIcon from "./../Images/VisoLabsIcon";
import GoBackIcon from "@material-ui/icons/SubdirectoryArrowLeft";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

import { ButtonBase } from "@material-ui/core";
import "./HomeButton.scss";
import { useSelector } from "react-redux";

function HomeButton(props) {
  const sessionIds = useSelector(state => state.session.sessionIds);

  let url = "/";
  if (sessionIds) {
    if (sessionIds.frameId) {
      url = `/storyboard/${sessionIds.storyboardId}`;
    } else if (sessionIds.storyboardId) {
      url = `/projects`;
    } else if (sessionIds.projectId) {
      url = `/projects`;
    }
  }

  if (props.match.url.endsWith("/preview")) {
    url = props.match.url.replace("/preview", "");
  }

  const atRoot = !!(
    props.match.url === "/" ||
    props.match.url === "/signin" ||
    props.match.url === "/projects"
  );

  return (
    <Link to={url}>
      <ButtonBase className="app-bar-back-button">
        {atRoot ? <VisoLabsIcon /> : <GoBackIcon />}
      </ButtonBase>
    </Link>
  );
}

export default withRouter(HomeButton);
