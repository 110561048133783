import axios from "axios";

import Firebase from "../firebase";
import config from "./../config.json";

const firebase = new Firebase();

function log(...args) {
  // console.log(...args);
}

const USER_PROJECT_ROLES = {
  admin: "admin",
  editor: "editor",
  _legacy: true
};

class Backend {
  static getUser() {
    const currentUser = firebase.auth.currentUser;
    return currentUser;
  }

  static async updateCurrentUserInfo() {
    log("Backend.updateCurrentUserInfo");

    const currentUser = this.getUser();
    const data = {
      name: currentUser.displayName || "",
      email: currentUser.email || "",
      photoURL: currentUser.photoURL || "",
      _updated: firebase.app.firestore.FieldValue.serverTimestamp()
    };
    await firebase.db
      .collection("users")
      .doc(currentUser.uid)
      .set(data, { merge: true });

    return true;
  }

  static async getProjectUsers(projectId) {
    log("Backend.getProjectUsers", projectId);
    const queryResult = await firebase.db
      .collection("users")
      .where(`projects.${projectId}`, "in", Object.values(USER_PROJECT_ROLES))
      .get();
    const users = queryResult.docs
      .map(doc => ({ ...doc.data(), id: doc.id }))
      .filter(user => user);
    return users;
  }

  static async getUserInfo(userId) {
    log("Backend.getUser", userId);
    const user = await firebase.db
      .collection("users")
      .doc(userId)
      .get();
    return { ...user.data(), id: user.id };
  }

  static async addUserToProjectByEmail(
    email,
    projectId,
    role = USER_PROJECT_ROLES.editor
  ) {
    log("Backend.addUserToProjectByEmail", email, projectId);
    const matchingUsers = await firebase.db
      .collection("users")
      .where("email", "==", email)
      .get();

    if (matchingUsers.empty) {
      throw Error(`${email} is not a registered user`);
    } else if (matchingUsers.size > 1) {
      throw Error(`Multiple users detected with email=${email}`);
    }

    const userId = matchingUsers.docs[0].id;
    await Promise.all([
      this.addUserToProject(userId, projectId, role),
      this.addProjectToUser(projectId, userId, role)
    ]).catch(error => console.log(error));
  }

  static async addUserToProject(
    userId,
    projectId,
    role = USER_PROJECT_ROLES.editor
  ) {
    // Note: This assumes the user is listed in users/$userId/projects/
    return await firebase.db
      .collection("projects")
      .doc(projectId)
      .update({
        [`users.${userId}`]: role,
        _updated: firebase.app.firestore.FieldValue.serverTimestamp()
      });
  }

  static async removeUserFromProject(userId, projectId) {
    await firebase.db
      .collection("projects")
      .doc(projectId)
      .update({
        [`users.${userId}`]: false,
        _updated: firebase.app.firestore.FieldValue.serverTimestamp()
      });
    await firebase.db
      .collection("users")
      .doc(userId)
      .update({
        [`projects.${projectId}`]: false,
        _updated: firebase.app.firestore.FieldValue.serverTimestamp()
      });
  }

  static async createProject(name) {
    log("Backend.createProject");
    const userId = this.getUser().uid;
    const data = {
      name: name || "Untitled project",
      users: { [userId]: USER_PROJECT_ROLES.admin },
      creator: userId,
      creation_time: new Date().getTime()
    };
    const project = await firebase.db.collection("projects").add(data);
    await this.addProjectToUser(project.id, userId, USER_PROJECT_ROLES.admin);
    return project;
  }

  static async updateProjectName(projectId, name) {
    log("Backend.updateProjectName", projectId, name);
    console.log(firebase);
    firebase.db
      .collection("projects")
      .doc(projectId)
      .update({
        name: name,
        _updated: firebase.app.firestore.FieldValue.serverTimestamp()
      });
  }

  static async archiveProject(projectId) {
    log("Backend.archiveProject", projectId);
    if (!projectId) {
      throw new Error("Project ID not specified");
    }
    return await firebase.db
      .collection("projects")
      .doc(projectId)
      .update({
        archived: true,
        _updated: firebase.app.firestore.FieldValue.serverTimestamp()
      });
  }

  static async addProjectToUser(
    projectId,
    userId,
    role = USER_PROJECT_ROLES.editor
  ) {
    // Note: This assumes the user is listed in projects/$projectId/users/
    await firebase.db
      .collection("users")
      .doc(userId)
      .update({
        [`projects.${projectId}`]: role,
        _updated: firebase.app.firestore.FieldValue.serverTimestamp()
      });
  }

  static async addStoryboardToProject(storyboardId, projectId) {
    // Note: This assumes that storyboards/$storyboardId exists
    await firebase.db
      .collection("projects")
      .doc(projectId)
      .update({
        [`storyboards.${storyboardId}`]: true,
        _updated: firebase.app.firestore.FieldValue.serverTimestamp()
      });
  }

  static async addFrameToStoryboard(
    frameId,
    storyboardId,
    relativeToFrameId = null
  ) {
    // Note: This assumes that frames/$frameId exists
    await firebase.db
      .collection("storyboards")
      .doc(storyboardId)
      .update({
        [`frames.${frameId}`]: true,
        _updated: firebase.app.firestore.FieldValue.serverTimestamp()
      });

    const storyboardSnapshot = await firebase.db
      .collection("storyboards")
      .doc(storyboardId)
      .get();
    const storyboardData = storyboardSnapshot.data();
    const orderedFrames = storyboardData.orderedFrames || [];
    let relativeFrameIndex = orderedFrames.indexOf(relativeToFrameId) + 1;

    if (relativeFrameIndex <= 0) {
      relativeFrameIndex = orderedFrames.length;
    }

    orderedFrames.splice(relativeFrameIndex, 0, frameId);
    await this.updateStoryboardOrderedFrames(storyboardId, orderedFrames);
  }

  static async getProject(projectId) {
    log("Backend.getProject", projectId);
    const snapshot = await firebase.db
      .collection("projects")
      .doc(projectId)
      .get();
    const project = { ...snapshot.data(), id: snapshot.id };
    return project;
  }

  static async getProjects(userId) {
    if (!userId) {
      userId = this.getUser().uid;
    }
    log("Backend.getProjects", userId);
    const queryResult = await firebase.db
      .collection("projects")
      .where(`users.${userId}`, "in", Object.values(USER_PROJECT_ROLES))
      .get();
    const projects = queryResult.docs
      .map(doc => ({ ...doc.data(), id: doc.id }))
      .filter(project => project && !project.archived);
    return projects;
  }

  static async getAllProjects() {
    log("Backend.getAllProjects");
    const queryResult = await firebase.db.collection("projects").get();
    const projects = queryResult.docs
      .map(doc => ({ ...doc.data(), id: doc.id }))
      .filter(project => project);
    return projects;
  }

  static async getMostRecentFrames(limit) {
    log("Backend.getMostRecentFrames");
    const queryResult = await firebase.db
      .collection("frames")
      .orderBy("thumbnailTimestamp", "desc")
      .limit(limit)
      .get();
    const frames = queryResult.docs
      .map(doc => ({ ...doc.data(), id: doc.id }))
      .filter(frame => frame);
    return frames;
  }

  static async createStoryboard(projectId, data = null) {
    log("Backend.createStoryboard", projectId);
    if (!data) {
      data = {
        name: "Untitled storyboard",
        format: {
          width: 1920,
          height: 1080
        }
      };
    }

    data.projectId = projectId;

    const storyboard = await firebase.db.collection("storyboards").add(data);

    return this.addStoryboardToProject(storyboard.id, projectId).then(() => {
      return storyboard.id;
    });
  }

  static async updateStoryboardName(storyboardId, name) {
    log("Backend.updateStoryboardName", storyboardId, name);
    await firebase.db
      .collection("storyboards")
      .doc(storyboardId)
      .update({
        name: name || "",
        _updated: firebase.app.firestore.FieldValue.serverTimestamp()
      });
  }

  static async updateStoryboardDescription(storyboardId, description) {
    log("Backend.updateStoryboardDescription", storyboardId, description);
    await firebase.db
      .collection("storyboards")
      .doc(storyboardId)
      .update({
        description: description || "",
        _updated: firebase.app.firestore.FieldValue.serverTimestamp()
      });
  }

  static async updateStoryboard(storyboardId, data) {
    log("Backend.updateStoryboard", storyboardId, data);
    data = {
      ...data,
      _updated: firebase.app.firestore.FieldValue.serverTimestamp()
    };
    await firebase.db
      .collection("storyboards")
      .doc(storyboardId)
      .set(data, { merge: true });
  }

  static async updateStoryboardOrderedFrames(storyboardId, frameIds) {
    log("Backend.updateStoryboardOrderedFrames", storyboardId, frameIds);
    await firebase.db
      .collection("storyboards")
      .doc(storyboardId)
      .update({
        orderedFrames: frameIds,
        _updated: firebase.app.firestore.FieldValue.serverTimestamp()
      });
  }

  static async archiveStoryboard(storyboardId) {
    log("Backend.archiveStoryboard", storyboardId);
    if (!storyboardId) {
      throw new Error("Storyboard ID not specified");
    }
    await firebase.db
      .collection("storyboards")
      .doc(storyboardId)
      .update({
        archived: true,
        _updated: firebase.app.firestore.FieldValue.serverTimestamp()
      });
  }

  static async getStoryboards(projectId) {
    log("Backend.getStoryboards", projectId);
    const queryResult = await firebase.db
      .collection("storyboards")
      .where(`projectId`, "==", projectId)
      .get();
    const storyboards = queryResult.docs
      .map(doc => ({ ...doc.data(), id: doc.id }))
      .filter(storyboard => storyboard && !storyboard.archived);
    return storyboards;
  }

  static async getStoryboard(storyboardId) {
    log("Backend.getStoryboard", storyboardId);
    const snapshot = await firebase.db
      .collection("storyboards")
      .doc(storyboardId)
      .get();
    const storyboard = { ...snapshot.data(), id: snapshot.id };
    return storyboard;
  }

  static async getFrames(storyboardId, projectId) {
    log("Backend.getFrames", storyboardId, projectId);
    if (!projectId) {
      const storyboard = await this.getStoryboard(storyboardId);
      projectId = storyboard.projectId;
    }
    const queryResult = await firebase.db
      .collection("frames")
      .where(`storyboardId`, "==", storyboardId)
      .where(`projectId`, "==", projectId)
      .get();
    const frames = queryResult.docs
      .map(doc => ({ ...doc.data(), id: doc.id }))
      .filter(frame => frame && !frame.archived);
    return frames;
  }

  static async getFrame(frameId) {
    log("Backend.getFrame", frameId);
    const snapshot = await firebase.db
      .collection("frames")
      .doc(frameId)
      .get();
    const frame = { ...snapshot.data(), id: snapshot.id };
    return frame;
  }

  static async createFrame(
    storyboardId,
    data = null,
    relativeToFrameId = null
  ) {
    log("Backend.createFrame");

    const storyboard = await this.getStoryboard(storyboardId);
    if (!data) {
      data = {};
    }

    data.storyboardId = storyboardId;
    data.projectId = storyboard.projectId;

    const frame = await firebase.db.collection("frames").add(data);
    await this.addFrameToStoryboard(frame.id, storyboardId, relativeToFrameId);

    return frame.id;
  }

  static async duplicateFrame(frameId, relativeToFrameId = null) {
    log("Backend.duplicateFrame", frameId);
    if (!frameId) {
      throw new Error("Frame ID not specified");
    }
    const doc = await firebase.db
      .collection("frames")
      .doc(frameId)
      .get();
    const origFrame = doc.data();

    return this.createFrame(
      origFrame.storyboardId,
      origFrame,
      relativeToFrameId
    );
  }

  static async archiveFrame(frameId) {
    log("Backend.archiveFrame", frameId);
    if (!frameId) {
      throw new Error("Frame ID not specified");
    }
    return await firebase.db
      .collection("frames")
      .doc(frameId)
      .update({
        archived: true,
        _updated: firebase.app.firestore.FieldValue.serverTimestamp()
      });
  }

  static async updateFrameName(frameId, name) {
    log("Backend.updateFrameName", frameId, name);
    await firebase.db
      .collection("frames")
      .doc(frameId)
      .update({
        name: name || "",
        _updated: firebase.app.firestore.FieldValue.serverTimestamp()
      });
  }

  static async updateFrameDescription(frameId, description) {
    log("Backend.updateFrameDescription", frameId, description);
    await firebase.db
      .collection("frames")
      .doc(frameId)
      .update({
        description: description || "",
        _updated: firebase.app.firestore.FieldValue.serverTimestamp()
      });
  }

  static saveFDL(frameId, data) {
    console.log("SAVING TO SERVER", frameId, data);
    firebase.db
      .collection("frames")
      .doc(frameId)
      .update({
        fdl: data,
        _updated: firebase.app.firestore.FieldValue.serverTimestamp()
      });
  }

  static addFDLCallback(frameId, callback) {
    const unsubscribe = firebase.db
      .collection("frames")
      .doc(frameId)
      .onSnapshot(doc => {
        console.log("Received server fdl updates");
        const data = doc.data();
        callback(data.fdl || {});
      });
    return unsubscribe;
  }

  static async getFDL(frameId) {
    log("Backend.getFDL", frameId);
    const doc = await firebase.db
      .collection("frames")
      .doc(frameId)
      .get();
    const fdl = doc.data().fdl || {};
    return fdl;
  }

  static async addPublicBackground(data, file) {
    return this.__addBackground("public", data, file);
  }

  static async addPrivateBackground(projectId, data, file) {
    return this.__addBackground(projectId, data, file);
  }

  static async __addBackground(projectId, data, file) {
    log("Backend.addBackground", projectId, data, file);

    const url = await this.__uploadImage(projectId, file).then(uploadedFile =>
      uploadedFile.ref.getDownloadURL()
    );

    data.projectId = projectId;
    data.images = { default: { src: url } };

    return firebase.db.collection("backgrounds").add(data);
  }

  static async getPrivateAndPublicBackgrounds(projectId) {
    return Promise.all([
      this.__getBackgrounds(projectId),
      this.__getBackgrounds("public")
    ]).then(results => {
      return [...results[0], ...results[1]];
    });
  }
  static async getPublicBackgrounds() {
    return this.__getBackgrounds("public");
  }

  static async getPrivateBackgrounds(projectId) {
    return this.__getBackgrounds(projectId);
  }

  static __uploadImage(projectId, file) {
    const fileId = Math.random()
      .toString(36)
      .substring(9);
    const ref = firebase.storage.ref(`${projectId}/${fileId}/file`);
    firebase.storage.ref(`${projectId}/${fileId}/images/file`);
    return ref.put(file);
  }

  static async __getBackgrounds(projectId) {
    if (!projectId) {
      throw new Error("Project ID not specified");
    }
    log("Backend.getBackgrounds", projectId);
    const backgrounds = await firebase.db
      .collection("backgrounds")
      .where("projectId", "==", projectId)
      .get();
    return backgrounds.docs
      .map(doc => {
        return { ...doc.data(), id: doc.id };
      })
      .filter(bg => bg.name && bg.images);
  }

  static async getPublicBackground(assetId) {
    return this.__getBackground("public", assetId);
  }

  static async getPrivateBackground(projectId, assetId) {
    return this.__getBackground(projectId, assetId);
  }

  static async __getBackground(projectId, assetId) {
    log("Backend.getBackground", projectId, assetId);
    const snapshot = await firebase.db
      .collection("backgrounds")
      .doc(assetId)
      .get();
    const asset = { ...snapshot.data(), id: snapshot.id };
    return asset;
  }

  static async addPublicProp(data, file) {
    return this.__addProp("public", data, file);
  }

  static async addPrivateProp(projectId, data, file) {
    return this.__addProp(projectId, data, file);
  }

  static async __addProp(projectId, data, file) {
    log("Backend.addProp", projectId, data, file);

    const url = await this.__uploadImage(projectId, file).then(uploadedFile =>
      uploadedFile.ref.getDownloadURL()
    );

    data.projectId = projectId;
    data.images = { default: { src: url } };

    return firebase.db.collection("props").add(data);
  }

  static async getPrivateAndPublicProps(projectId) {
    return Promise.all([
      this.__getProps(projectId),
      this.__getProps("public")
    ]).then(results => {
      return [...results[0], ...results[1]];
    });
  }
  static async getPublicProps() {
    return this.__getProps("public");
  }

  static async getPrivateProps(projectId) {
    return this.__getProps(projectId);
  }

  static async __getProps(projectId) {
    if (!projectId) {
      throw new Error("Project ID not specified");
    }
    log("Backend.getProps", projectId);
    const props = await firebase.db
      .collection("props")
      .where("projectId", "==", projectId)
      .get();
    return props.docs
      .map(doc => {
        return { ...doc.data(), id: doc.id };
      })
      .filter(prop => prop.name && prop.images);
  }

  static async getPublicProp(assetId) {
    return this.__getProp("public", assetId);
  }

  static async getPrivateProp(projectId, assetId) {
    return this.__getProp(projectId, assetId);
  }

  static async __getProp(projectId, assetId) {
    log("Backend.getProp", projectId, assetId);
    const snapshot = await firebase.db
      .collection("props")
      .doc(assetId)
      .get();
    const asset = { ...snapshot.data(), id: snapshot.id };
    return asset;
  }

  static async addPublicCharacter(data, defaultImage, altImages) {
    return this.__addCharacter("public", data, defaultImage, altImages);
  }

  static async addPrivateCharacter(projectId, data, defaultImage, altImages) {
    return this.__addCharacter(projectId, data, defaultImage, altImages);
  }

  static async __addCharacter(projectId, data, defaultImage, altImages) {
    /*
    defaultImage = "alfred-lying-50mm-high-0.png";
    images = [{height: "high", angle: 0, file:"alfred-lying-50mm-high-0.png"}];
    */
    log(
      "Backend.addCharacter",
      projectId,
      data,
      defaultImage,
      altImages.length
    );

    const defaultImageResult = await this.__uploadImage(
      projectId,
      defaultImage
    ).then(uploadedFile => {
      return uploadedFile.ref.getDownloadURL();
    });

    const altImagesResult = await Promise.all(
      altImages.map(image => {
        return this.__uploadImage(projectId, image.file)
          .then(uploadedFile => {
            return uploadedFile.ref.getDownloadURL();
          })
          .then(url => {
            return { angle: image.angle, height: image.height, src: url };
          });
      })
    );

    const uploadData = { ...data, images: {} };
    uploadData.images.default = { src: defaultImageResult };
    uploadData.images.alt = altImagesResult;
    uploadData.projectId = projectId;

    await firebase.db.collection("characters").add(uploadData);
  }

  static async getPublicCharacters() {
    return this.__getCharacters("public");
  }

  static async getPrivateCharacters(projectId) {
    return this.__getCharacters(projectId);
  }

  static async __getCharacters(projectId) {
    log("Backend.getCharacters", projectId);
    const characters = await firebase.db
      .collection("characters")
      .where("projectId", "==", projectId)
      .get();
    return characters.docs.map(doc => {
      return { ...doc.data(), id: doc.id };
    });
  }

  static async getPublicCharacter(assetId) {
    return this.__getCharacter("public", assetId);
  }

  static async getPrivateCharacter(projectId, assetId) {
    return this.__getCharacter(projectId, assetId);
  }

  static async __getCharacter(projectId, assetId) {
    log("Backend.getCharacter", projectId, assetId);
    const snapshot = await firebase.db.collection("characters").get(assetId);
    const asset = { ...snapshot.data(), id: snapshot.id };
    return asset;
  }

  static async pixabaySearch(searchString) {
    log("Backend.pixabaySearch", searchString);

    const encodedSearchString = encodeURI(searchString);
    const functionName = "pixabaySearch";
    const region = config.firebase.region;
    const projectId = config.firebase.projectId;
    const url = `https://${region}-${projectId}.cloudfunctions.net/${functionName}?q=${encodedSearchString}`;

    const token = await this.getUser().getIdToken();

    const httpClient = axios.create({
      timeout: 30000,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json"
      }
    });
    const response = await httpClient.get(url);

    return response.data;
  }

  static async downloadStoryboardPDF(storyboardId) {
    // TODO: Switch to POST
    log("Backend.downloadStoryboardPDF", storyboardId);

    const functionName = "storyboardExportToPdf";
    const region = config.firebase.region;
    const projectId = config.firebase.projectId;
    const url = `https://${region}-${projectId}.cloudfunctions.net/${functionName}`;

    const token = await this.getUser().getIdToken();

    const httpClient = axios.create({
      timeout: 30000,
      responseType: "arraybuffer",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json"
      }
    });
    await httpClient
      .post(url, { storyboardId: storyboardId })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "storyboard.pdf");
        document.body.appendChild(link);
        link.click();
      });
  }

  static async pixabayImport(projectId, imageData, assetType) {
    log("Backend.pixabayImport", projectId, imageData, assetType);

    if (!(assetType === "background" || assetType === "prop")) {
      throw Error(`Unrecognised asset type (${assetType})`);
    }

    const functionName = "pixabayImport";
    const region = config.firebase.region;
    const firebaseProjectId = config.firebase.projectId;
    const url = `https://${region}-${firebaseProjectId}.cloudfunctions.net/${functionName}`;

    const token = await this.getUser().getIdToken();

    const httpClient = axios.create({
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json"
      }
    });
    const response = await httpClient.post(url, {
      projectId: projectId,
      imageData: imageData,
      assetType: assetType
    });
    return response.data.id;
  }
}

export default Backend;
