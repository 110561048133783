import React from "react";

import { Typography } from "@material-ui/core";

export default function PreviewImage(props) {
  const { url, text } = props;
  return (
    <div className="preview-image">
      <img src={url || ""} alt="" draggable={false} />
      <Typography className="preview-image-text">{text}</Typography>
    </div>
  );
}
