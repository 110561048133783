import React from "react";
import FrameCanvas from "./FrameCanvas";
import Outliner from "../Outliner/Outliner";
import OutlinerDivider from "../Outliner/OutlinerDivider";
import InstanceOutliner from "./FrameOutliner/Instances";
import Properties from "./FrameOutliner/Properties.js";
import { useSyncLocalFdlToServer, useSyncServerFdlToLocalState } from "./hooks";
import FrameToolbox from "./FrameOutliner/FrameToolbox";

export default function FrameEditor(props) {
  useSyncLocalFdlToServer();
  useSyncServerFdlToLocalState();

  return (
    <div>
      <Outliner>
        <FrameToolbox />
        <OutlinerDivider major />
        <InstanceOutliner />
        <Properties />
      </Outliner>
      <div className="outliner-partner-page">
        <FrameCanvas />
      </div>
    </div>
  );
}
