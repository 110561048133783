import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Menu, MenuItem, Paper, Typography, Input } from "@material-ui/core";
import PreviewImage from "./PreviewImage";
import UserBadge from "../Users/UserBadge";
import { useUserInfo, useProjectUsers } from "./hooks";
import "./ProjectCard.scss";
import Backend from "../../backend";
import MenuIcon from "@material-ui/icons/MoreVert";
import EditIcon from "@material-ui/icons/Edit";

function Title(props) {
  const { project } = props;
  const [_value, _setValue] = React.useState(project.name);
  const [_isInEditMode, _setIsInEditMode] = React.useState(null);

  const inputRef = React.createRef();

  const _saveValue = React.useCallback(
    name => {
      return Backend.updateProjectName(project.id, name);
    },
    [project.id]
  );

  useEffect(() => {
    _setValue(project.name);
  }, [project.name]);

  useEffect(() => {
    if (_isInEditMode === false) {
      Backend.updateProjectName(project.id, _value);
    }
  }, [_isInEditMode, project.id, _value]);

  return (
    <div className="title">
      <Input
        type="text"
        inputRef={inputRef}
        value={_value}
        onFocus={() => _setIsInEditMode(true)}
        onBlur={() => _setIsInEditMode(false)}
        onChange={e => _setValue(e.target.value)}
        onKeyPress={e => (e.key === "Enter" ? _saveValue(_value) : null)}
        disableUnderline={true}
        fullWidth={true}
      />
      <EditIcon
        className="edit-icon"
        onClick={() => {
          inputRef.current.select();
        }}
      />
    </div>
  );
}

function ProjectMenu(props) {
  const { anchorEl, setAnchorEl, project } = props;

  function handleMenuClose() {
    setAnchorEl(null);
  }
  function archiveProject() {
    Backend.archiveProject(project.id);
    project.archived = true;
    handleMenuClose();
  }

  return (
    <Menu
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
      keepMounted
    >
      <MenuItem onClick={() => archiveProject(project.id)}>
        Archive Project
      </MenuItem>
    </Menu>
  );
}

export default function ProjectCard(props) {
  const { project } = props;
  const url = `/project/${project.id}`;
  const { value: users, setDoFetch: fetchUsers } = useProjectUsers(project.id);
  useEffect(() => fetchUsers(true), [fetchUsers]);

  const creatorId = project.creator;
  const { value: creator, setDoFetch: fetchCreator } = useUserInfo(creatorId);
  useEffect(() => fetchCreator(true), [fetchCreator]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  function onMenuClicked(event) {
    setAnchorEl(event.currentTarget);
  }

  return project.archived ? null : (
    <Paper className="project-card">
      <ProjectMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        project={project}
      />
      <div className="project-card-header">
        <Title project={project} />
        <MenuIcon className="menu-button" onClick={onMenuClicked} />
      </div>
      <Link className="project-card-a" to={url}>
        <div className="image-container">
          <PreviewImage url={project.thumbnail} />
        </div>
        <Typography className="details" variant="body1">
          Created by: {(creator && creator.email) || ""}
          <br />
          Created:{" "}
          {(project.creation_time &&
            new Date(project.creation_time).toUTCString()) ||
            ""}
        </Typography>
        <div className="users">
          {users.map(user => {
            return <UserBadge key={user.id} user={user} />;
          })}
        </div>
      </Link>
    </Paper>
  );
}
