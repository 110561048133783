import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useStoryboard } from "./../Storyboards/hooks";

import { fabric } from "fabric";
import "./FrameCanvas.scss";

const DEFAULT_ASPECT_RATIO = [1920, 1080];

function getFormat(canvas) {
  return [
    (canvas.format && canvas.format.width && parseInt(canvas.format.width)) ||
      DEFAULT_ASPECT_RATIO[0],
    (canvas.format && canvas.format.height && parseInt(canvas.format.height)) ||
      DEFAULT_ASPECT_RATIO[1]
  ];
}
function fdlTranformToFabric(posData) {
  return {
    left: posData.offset ? posData.offset[0] : null,
    top: posData.offset ? posData.offset[1] : null,
    scaleX: posData.scale ? posData.scale[0] : 1,
    scaleY: posData.scale ? posData.scale[1] : 1,
    flipX: posData.flipX ? true : false,
    flipY: posData.flipY ? true : false,
    angle: posData.angle ? posData.angle : 0,
    originX: "center",
    originY: "center"
  };
}

function fabricToFdlTransform(object, canvas) {
  const objectTransform = object.calcTransformMatrix();
  return {
    flipX: object.flipX,
    flipY: object.flipY,
    scale: [object.scaleX, object.scaleY],
    offset: [objectTransform[4], objectTransform[5]],
    angle: object.angle
  };
}

function configureNavigationControls(canvas) {
  let initScale = null;
  let isTouchZooming = false;
  let lastPos = null;

  canvas.on("touch:gesture", function(opt) {
    if (!opt.e.touches) {
      return;
    }

    if (opt.e.touches.length === 2) {
      if (opt.self.state === "start") {
        if (!canvas.getActiveObject()) {
          isTouchZooming = true;
          initScale = canvas.getZoom();
        }
      } else if (opt.self.state === "change") {
        if (isTouchZooming) {
          let zoom = initScale * opt.self.scale;

          if (zoom > 4) zoom = 4; // Zoom in limit
          if (zoom < 0.05) zoom = 0.05; // Zoom out limit

          canvas.zoomToPoint({ x: opt.self.x, y: opt.self.y }, zoom);

          resizeObjectControls(canvas);
        }
      } else if (opt.self.state === "end") {
        isTouchZooming = false;
        initScale = null;
      }
    }
  });

  canvas.on("mouse:wheel", function(opt) {
    var delta = -opt.e.deltaY;
    delta = typeof InstallTrigger !== "undefined" ? delta * 20 : delta; // Adjust delta for Firefox

    var zoom = canvas.getZoom();
    zoom = zoom + delta / 1500;
    if (zoom > 4) zoom = 4; // Zoom in limit
    if (zoom < 0.05) zoom = 0.05; // Zoom out limit

    canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);

    resizeObjectControls(canvas);
    opt.e.preventDefault();
    opt.e.stopPropagation();
  });

  canvas.on("mouse:down", function(opt) {
    if (
      canvas.getActiveObject() === undefined ||
      canvas.getActiveObject() === null
    ) {
      let x = opt.e.hasOwnProperty("clientX") ? opt.e.clientX : opt.pointer.x;
      let y = opt.e.hasOwnProperty("clienty") ? opt.e.clientY : opt.pointer.y;
      lastPos = [x, y];

      canvas.isDragging = true;
      canvas.lastPosX = opt.e.clientX;
      canvas.lastPosY = opt.e.clientY;
    }
  });

  canvas.on("mouse:move", function(opt) {
    if (canvas.isRotateControlActivated || canvas.isCamHeightControlActivated) {
      return;
    }
    if (canvas.isDragging) {
      let x = opt.e.hasOwnProperty("clientX") ? opt.e.clientX : opt.pointer.x;
      let y = opt.e.hasOwnProperty("clienty") ? opt.e.clientY : opt.pointer.y;

      canvas.viewportTransform[4] += x - lastPos[0];
      canvas.viewportTransform[5] += y - lastPos[1];
      canvas.renderAll();
      lastPos = [x, y];
    }
  });
  canvas.on("mouse:up", function(opt) {
    canvas.isDragging = false;
    canvas.getObjects().forEach(obj => obj.setCoords());
  });
}

function useFabricCanvas(
  canvasRef,
  frameId,
  sidebar_width,
  appbar_height,
  storyboard
) {
  const [fabricCanvas, setFabricCanvas] = React.useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!frameId) {
      return;
    }
    if (!canvasRef.current) {
      return;
    }
    if (!storyboard) {
      return;
    }
    if (fabricCanvas) {
      return;
    }

    const newFabricCanvas = new fabric.Canvas(canvasRef.current);
    newFabricCanvas.format = storyboard.format;
    setFabricCanvas(newFabricCanvas);
    newFabricCanvas.preserveObjectStacking = true;
    newFabricCanvas.selection = false; // Disable group selection

    configureNavigationControls(newFabricCanvas);

    newFabricCanvas.on("mouse:down:before", function(opt) {
      const rotateObj = getInstanceById(newFabricCanvas, "rotateControls");
      const rotateMarker = getInstanceById(newFabricCanvas, "rotateMarker");
      if (
        rotateObj &&
        (opt.target === rotateObj || opt.target === rotateMarker)
      ) {
        let x = opt.e.hasOwnProperty("clientX") ? opt.e.clientX : opt.pointer.x;
        newFabricCanvas.isRotateControlActivated = true;
        newFabricCanvas.initialX = x;
      }
      const camHeightObj = getInstanceById(
        newFabricCanvas,
        "camHeightControls"
      );
      const camHeightMarker = getInstanceById(
        newFabricCanvas,
        "camHeightMarker"
      );

      if (
        camHeightObj &&
        (opt.target === camHeightObj || opt.target === camHeightMarker)
      ) {
        let y = opt.e.hasOwnProperty("clientY") ? opt.e.clientY : opt.pointer.y;
        newFabricCanvas.isCamHeightControlActivated = true;
        newFabricCanvas.initialY = y;
      }
    });

    newFabricCanvas.on("mouse:over", function(opt) {
      const rotateObj = getInstanceById(newFabricCanvas, "rotateControls");
      const rotateMarker = getInstanceById(newFabricCanvas, "rotateMarker");
      if (
        opt.target === rotateObj ||
        opt.target === rotateMarker ||
        newFabricCanvas.isRotateControlActivated
      ) {
        rotateObj &&
          rotateObj.set({
            opacity: 1
          });
      } else {
        rotateObj &&
          rotateObj.set({
            opacity: 0.6
          });
      }

      const camHeightObj = getInstanceById(
        newFabricCanvas,
        "camHeightControls"
      );
      const camHeightMarker = getInstanceById(
        newFabricCanvas,
        "camHeightMarker"
      );
      if (
        opt.target === camHeightObj ||
        opt.target === camHeightMarker ||
        newFabricCanvas.isCamHeightControlActivated
      ) {
        camHeightObj &&
          camHeightObj.set({
            opacity: 1
          });
      } else {
        camHeightObj &&
          camHeightObj.set({
            opacity: 0.6
          });
      }

      newFabricCanvas.renderAll();
    });

    newFabricCanvas.on("mouse:out", function(opt) {
      const rotateObj = getInstanceById(newFabricCanvas, "rotateControls");
      if (!newFabricCanvas.isRotateControlActivated) {
        rotateObj &&
          rotateObj.set({
            opacity: 0.6
          });
      }

      const camHeightObj = getInstanceById(
        newFabricCanvas,
        "camHeightControls"
      );
      if (!newFabricCanvas.isCamHeightControlActivated) {
        camHeightObj &&
          camHeightObj.set({
            opacity: 0.6
          });
      }

      newFabricCanvas.renderAll();
    });

    newFabricCanvas.on("mouse:move", function(opt) {
      if (newFabricCanvas.isRotateControlActivated) {
        let x = opt.e.hasOwnProperty("clientX") ? opt.e.clientX : opt.pointer.x;
        const rotateObj = getInstanceById(newFabricCanvas, "rotateControls");
        if (!rotateObj) {
          return;
        }

        // Get a value of 0-1
        const x0 = rotateObj.oCoords.ml.x;
        const x1 = rotateObj.oCoords.mr.x;
        let value = (x - x0) / (x1 - x0);
        value = Math.min(Math.max(value, 0), 1);

        // Map 0-1 to [0,15, ...360]
        const rotation = parseInt(value * 23) * 15;

        dispatch({
          type: "UPDATE_CHARACTER",
          charId: rotateObj.targetObject.id,
          updates: {
            alt: {
              angle: rotation
            }
          }
        });
        drawCharacterControls(newFabricCanvas, rotateObj.targetObject.id);
      }

      if (newFabricCanvas.isCamHeightControlActivated) {
        let y = opt.e.hasOwnProperty("clientY") ? opt.e.clientY : opt.pointer.y;
        const camHeightObject = getInstanceById(
          newFabricCanvas,
          "camHeightControls"
        );
        if (!camHeightObject) {
          return;
        }

        // Get a value of 0-1
        const y0 = camHeightObject.oCoords.mt.y;
        const y1 = camHeightObject.oCoords.mb.y;
        let value = (y - y0) / (y1 - y0);
        value = Math.min(Math.max(value, 0), 1);

        const heights = ["xhigh", "high", "normal", "low", "xlow"];
        const height = heights[parseInt(value * 4)];

        dispatch({
          type: "UPDATE_CHARACTER",
          charId: camHeightObject.targetObject.id,
          updates: {
            alt: {
              height: height
            }
          }
        });
        drawCharacterControls(newFabricCanvas, camHeightObject.targetObject.id);
      }
    });

    newFabricCanvas.on("mouse:up", function(opt) {
      if (newFabricCanvas.isRotateControlActivated) {
        const rotateObj = getInstanceById(newFabricCanvas, "rotateControls");
        if (rotateObj) {
          newFabricCanvas.setActiveObject(rotateObj.targetObject);
          drawCharacterControls(newFabricCanvas, rotateObj.targetObject.id);
        }
      }
      newFabricCanvas.isRotateControlActivated = false;
      updateCharacterControls(newFabricCanvas);

      if (newFabricCanvas.isCamHeightControlActivated) {
        const camHeightObj = getInstanceById(
          newFabricCanvas,
          "camHeightControls"
        );
        if (camHeightObj) {
          newFabricCanvas.setActiveObject(camHeightObj.targetObject);
          drawCharacterControls(newFabricCanvas, camHeightObj.targetObject.id);
        }
      }
      newFabricCanvas.isCamHeightControlActivated = false;
      updateCharacterControls(newFabricCanvas);
    });

    newFabricCanvas.on("object:modified", event => {
      const object = event.target;
      if (object.assetType === "character") {
        const charId = object.id;
        const transform = fabricToFdlTransform(object, newFabricCanvas);
        dispatch({
          type: "UPDATE_CHARACTER",
          charId: charId,
          updates: transform,
          frameId: frameId
        });
        drawCharacterControls(newFabricCanvas, object.id);
      }
      if (object.assetType === "background") {
        const backgroundId = object.id;
        const transform = fabricToFdlTransform(object, newFabricCanvas);
        dispatch({
          type: "UPDATE_BACKGROUND",
          backgroundId: backgroundId,
          updates: transform,
          frameId: frameId
        });
      }
      if (object.assetType === "prop") {
        const propId = object.id;
        const transform = fabricToFdlTransform(object, newFabricCanvas);
        dispatch({
          type: "UPDATE_PROP",
          propId: propId,
          updates: transform,
          frameId: frameId
        });
      }
    });

    newFabricCanvas.on("object:moving", event => {
      const object = event.target;
      if (newFabricCanvas.getActiveObject() === object) {
        drawCharacterControls(newFabricCanvas, object.id);
      }
    });

    newFabricCanvas.on("object:selected", event => {
      let object = event.target;
      object.setOptions({
        opacity: 0.8,
        backgroundColor: "rgba(248, 177, 51, 0.5)"
      });
      dispatch({
        type: "SET_SELECTION",
        id: object.id
      });
      newFabricCanvas.getObjects().forEach(obj => {});

      drawCharacterControls(newFabricCanvas, object.id);
    });

    newFabricCanvas.on("object:scaling", event => {
      updateCharacterControls(newFabricCanvas);
    });

    newFabricCanvas.on("object:rotating", event => {
      updateCharacterControls(newFabricCanvas);
    });

    newFabricCanvas.on("selection:updated", event => {
      const object = event.target;
      newFabricCanvas.getObjects().forEach(obj => {
        if (obj !== object) {
          obj.setOptions({ opacity: 1, backgroundColor: null });
        }
      });
      object.setOptions({
        opacity: 0.8,
        backgroundColor: "rgba(248, 177, 51, 0.5)"
      });
      dispatch({
        type: "SET_SELECTION",
        id: object.id
      });
      drawCharacterControls(newFabricCanvas, object.id);
    });

    newFabricCanvas.on("selection:cleared", event => {
      newFabricCanvas.getObjects().forEach(obj => {
        obj.setOptions({ opacity: 1, backgroundColor: null });
      });
      dispatch({
        type: "SET_SELECTION",
        id: null
      });
      drawCharacterControls(newFabricCanvas, null);
    });
  }, [
    canvasRef,
    frameId,
    dispatch,
    appbar_height,
    sidebar_width,
    fabricCanvas,
    storyboard
  ]);

  useEffect(() => {
    let orientationChanged = false;

    const resizeCanvas = e => {
      if (fabricCanvas) {
        const origViewportTransform = [...fabricCanvas.viewportTransform];
        let origWidth = fabricCanvas.width;
        let origHeight = fabricCanvas.height;

        // Size the canvas to 0,0 so that the rest of the browser window/html can adjust
        // to orientation changes without being affected by the canvas dimentions at the
        // previous orientation
        fabricCanvas.setWidth(0);
        fabricCanvas.setHeight(0);

        let newWidth = window.innerWidth - sidebar_width;
        let newHeight = window.innerHeight - appbar_height;

        fabricCanvas.setWidth(newWidth);
        fabricCanvas.setHeight(newHeight);
        fabricCanvas.viewportTransform = origViewportTransform;

        if (orientationChanged) {
          orientationChanged = false;
          fabricCanvas.viewportTransform[4] =
            fabricCanvas.viewportTransform[4] - origWidth / 2 + newWidth / 2;
          fabricCanvas.viewportTransform[5] =
            fabricCanvas.viewportTransform[5] - origHeight / 2 + newHeight / 2;
        }
      }
    };
    window.addEventListener("resize", resizeCanvas);

    const switchOrientation = event => {
      orientationChanged = true;
    };
    window.addEventListener("orientationchange", switchOrientation);

    resizeCanvas();

    const cleanup = () => {
      window.removeEventListener("resize", resizeCanvas);
      window.removeEventListener("orientationchange", switchOrientation);
    };

    return cleanup;
  }, [fabricCanvas, sidebar_width, appbar_height]);

  return fabricCanvas;
}

function getInstanceById(canvas, id) {
  const matchingInstances = canvas.getObjects().filter(obj => obj.id === id);
  if (matchingInstances.length > 0) {
    return matchingInstances.pop();
  }
  return null;
}

function updateCharacterControls(canvas) {
  if (!canvas) {
    return;
  }

  const selectedObj = canvas.getActiveObject();
  if (selectedObj && selectedObj.assetType === "character") {
    drawCharacterControls(canvas, selectedObj.id);
  }
}

function drawCharacterControls(canvas, charId) {
  function removeControl(id) {
    const obj = getInstanceById(canvas, id);
    if (obj) {
      canvas.remove(obj);
    }
  }

  if (!charId) {
    if (!canvas.isRotateControlActivated) {
      removeControl("rotateControls");
      removeControl("rotateMarker");
    }
    if (!canvas.isCamHeightControlActivated) {
      removeControl("camHeightControls");
      removeControl("camHeightMarker");
    }

    return;
  }

  const charObj = getInstanceById(canvas, charId);
  if (!charObj || charObj.assetType !== "character") {
    return;
  }

  const rotatePath =
    "M269.505 37.7744L237.002 72.9553L238.995 78.9474C243.665 77.0488 248.244 74.6342 252.699 71.7215C266.155 61.2686 279.451 50.2894 292.69 39.16C295.303 36.4666 297.751 33.3911 300 29.9742C297.744 25.8144 295.935 19.1455 293.158 17.9117C277.864 11.1165 262.366 5.42131 246.84 0.0564173C245.171 -0.520299 242.869 3.47114 240.861 5.34345C242.163 8.43535 243.033 12.7359 244.859 14.3591C248.462 17.0726 252.2 19.3186 256.034 21.0748L255.577 26.1564H147H44.6137L44.1546 21.0748C48.0054 19.3186 51.759 17.0726 55.3783 14.3591C57.2112 12.7359 58.0857 8.43535 59.3928 5.34345C57.376 3.47114 55.0644 -0.520299 53.3882 0.0564173C37.7955 5.42131 22.2306 11.1165 6.87156 17.9117C4.0826 19.1455 2.26569 25.8144 2.67029e-05 29.9742C2.25876 33.3911 4.7167 36.4666 7.3414 39.16C20.6371 50.2894 33.9902 61.2686 47.5042 71.7215C51.9784 74.6342 56.5772 77.0488 61.2666 78.9474L63.269 72.9553L30.6257 37.7744L30.9815 35.1911C39.9102 36.2975 48.8283 38.0073 57.7696 38.3993C86.8655 39.6751 107.396 40.7628 136.5 41.271H155.548C184.528 40.7628 213.506 39.6751 242.477 38.3993C251.381 38.0073 260.26 36.2975 269.151 35.1911L269.505 37.7744Z";
  const camHeightPath =
    "M40.0105 31.809L75.442 64.057L81.4205 62.0179C79.4866 57.3617 77.0373 52.7996 74.0906 48.3654C63.5349 34.9843 52.4539 21.7675 41.2232 8.60894C38.5096 6.01502 35.4152 3.59003 31.9807 1.366C27.8369 3.65355 21.1801 5.51294 19.9667 8.29981C13.2843 23.6473 7.70373 39.1916 2.4537 54.7611C1.88933 56.4348 5.89884 58.7072 7.78657 60.7018C10.8694 59.3769 15.1644 58.4738 16.7744 56.6362C19.4615 53.0113 21.68 49.2562 23.4079 45.4079L28.494 45.8272L29.0735 123.378L29.487 178.716L30.0707 256.837L24.9914 257.334C23.2061 253.495 20.9316 249.758 18.1904 246.158C16.5532 244.337 12.2451 243.494 9.14278 242.21C7.28513 244.241 3.3101 246.583 3.89947 248.255C9.38207 263.811 15.1948 279.337 22.1063 294.649C23.3612 297.429 30.0452 299.196 34.2228 301.431C37.6236 299.147 40.6814 296.665 43.3558 294.02C54.3882 280.638 65.27 267.2 75.6241 253.605C78.5041 249.108 80.8848 244.49 82.7487 239.786L76.7404 237.827L41.7958 270.741L39.2093 270.404C40.2492 261.465 41.8927 252.533 42.218 243.586C43.2766 214.475 44.147 185.358 44.4379 156.243C43.7129 127.261 42.3302 87.8064 40.8376 58.8382C40.379 49.936 38.6024 41.067 37.4293 32.1828L40.0105 31.809Z";

  if (!canvas.isCamHeightControlActivated) {
    let rotateObj = getInstanceById(canvas, "rotateControls");
    if (!rotateObj) {
      rotateObj = new fabric.Path(`${rotatePath}`, {
        fill: "#9B9894",
        originX: "center",
        originY: "center",
        opacity: 0.6
      });

      rotateObj.id = "rotateControls";
      canvas.add(rotateObj);
    }

    rotateObj.left = charObj.left;
    rotateObj.top = charObj.top;

    rotateObj.centeredRotation = false;
    rotateObj.originY =
      -(
        (charObj.height * charObj.scaleY + rotateObj.height / 2) /
        (rotateObj.height * rotateObj.scaleY)
      ) / 2;
    rotateObj.rotate(charObj.angle);

    // rotateObj.opacity = 0.6;
    rotateObj.targetObject = charObj;
    rotateObj.selectable = false;
    rotateObj.setCoords();

    let rotateMarker = getInstanceById(canvas, "rotateMarker");
    if (!rotateMarker) {
      rotateMarker = new fabric.Circle({
        radius: 20,
        fill: "#fda00f",
        originX: "center",
        originY: "center"
      });

      rotateMarker.id = "rotateMarker";
      canvas.add(rotateMarker);
    }
    const rotation = (charObj.instance.alt && charObj.instance.alt.angle) || 0;
    rotateMarker.opacity = 1;
    rotateMarker.left = rotateObj.left;
    rotateMarker.top = rotateObj.top;
    rotateMarker.centeredRotation = false;
    rotateMarker.originY =
      -(
        (charObj.height * charObj.scaleY + rotateObj.height / 2) /
        rotateMarker.height
      ) /
        2 -
      0.1;
    rotateMarker.originX =
      (-(rotation / 345) * rotateObj.width) / rotateMarker.width +
      (0.5 * rotateObj.width) / rotateMarker.width +
      0.5;
    rotateMarker.originX *= 0.9; // Scale inwards a little to better match arrow head ends
    rotateMarker.selectable = false;
    rotateMarker.rotate(rotateObj.angle);
    rotateMarker.setCoords();
  }

  if (!canvas.isRotateControlActivated) {
    let camHeightObj = getInstanceById(canvas, "camHeightControls");
    if (!camHeightObj) {
      camHeightObj = new fabric.Path(`${camHeightPath}`, {
        fill: "#9B9894",
        originX: "center",
        originY: "center",
        opacity: 0.6
      });
      camHeightObj.rotate(-90);

      camHeightObj.id = "camHeightControls";
      canvas.add(camHeightObj);
    }

    camHeightObj.left = charObj.left;
    camHeightObj.top = charObj.top;

    camHeightObj.centeredRotation = false;
    camHeightObj.originX =
      -(
        (charObj.width * charObj.scaleX + camHeightObj.width / 2) /
        (camHeightObj.width * camHeightObj.scaleX)
      ) / 2;
    camHeightObj.rotate(charObj.angle);

    // camHeightObj.opacity = 0.6;
    camHeightObj.targetObject = charObj;
    camHeightObj.selectable = false;

    camHeightObj.setCoords();

    let camHeightMarker = getInstanceById(canvas, "camHeightMarker");
    if (!camHeightMarker) {
      camHeightMarker = new fabric.Circle({
        radius: 20,
        fill: "#fda00f",
        originX: "center",
        originY: "center"
      });

      camHeightMarker.id = "camHeightMarker";
      canvas.add(camHeightMarker);
    }
    const height =
      (charObj.instance.alt && charObj.instance.alt.height) || "normal";
    const heights = ["xhigh", "high", "normal", "low", "xlow"];
    const heightIdx = heights.indexOf(height) || 0;
    camHeightMarker.opacity = 1;
    camHeightMarker.left = camHeightObj.left;
    camHeightMarker.top = camHeightObj.top;
    camHeightMarker.centeredRotation = false;
    camHeightMarker.originX =
      -(
        (charObj.width * charObj.scaleX + camHeightObj.width / 2) /
        camHeightMarker.width
      ) /
        2 -
      0.15;
    camHeightMarker.originY =
      (-(heightIdx / 4) * camHeightObj.height) / camHeightMarker.height +
      (0.5 * camHeightObj.height) / camHeightMarker.height +
      0.5;
    camHeightMarker.originY *= 0.9; // Scale inwards a little to better match arrow head ends
    camHeightMarker.selectable = false;
    camHeightMarker.rotate(camHeightObj.angle);
    camHeightMarker.setCoords();
  }
}

function drawInstance(instanceId, instance, assetType, fabricCanvas) {
  const url = instance.src || instance.asset.images.default.src;

  const options = fdlTranformToFabric(instance);
  options.selectable = true;
  options.opacity = 1;
  options.crossOrigin = "anonymous";

  function clearDuplicateObjects() {
    // Also returns the single matching object, if it exists
    const matchingInstances = fabricCanvas
      .getObjects()
      .filter(obj => obj.id === instanceId);

    let obj;
    if (matchingInstances.length > 0) {
      obj = matchingInstances.pop();
    }

    // Delete any duplicate instances
    if (matchingInstances.length > 0) {
      matchingInstances.forEach(existingObj => {
        fabricCanvas.remove(existingObj);
      });
    }
    return obj;
  }

  let obj = clearDuplicateObjects();

  // Create a new image for the instance
  if (!obj) {
    fabric.Image.fromURL(
      url,
      obj => {
        obj.id = instanceId;
        obj.instance = instance;
        obj.url = url;
        obj.assetType = assetType;
        obj.depth = instance.depth;
        fabricCanvas.add(obj);

        if (options.left === null && options.top === null) {
          fabricCanvas.centerObject(obj);
        }
        obj.setCoords();
        obj.transparentCorners = false;
        obj.borderColor = "#f8b133";
        obj.cornerColor = "#1c1c1c";

        setObjectControlSize(obj, fabricCanvas);

        // Rerun this now as drawing can involve a race condition
        // from being triggered for success multiple events very quickly
        clearDuplicateObjects();

        obj.filtersMap = {};
        const gamma = new fabric.Image.filters.Gamma();
        obj.filters.push(gamma);
        obj.filtersMap.gamma = gamma;

        const saturation = new fabric.Image.filters.Saturation();
        obj.filters.push(saturation);
        obj.filtersMap.saturation = saturation;

        const brightness = new fabric.Image.filters.Brightness();
        obj.filters.push(brightness);
        obj.filtersMap.brightness = brightness;

        obj.filtersMap.gamma.setOptions({
          gamma: [
            instance.gammaR || 1,
            instance.gammaG || 1,
            instance.gammaB || 1
          ]
        });
        obj.filtersMap.saturation.setOptions({
          saturation: instance.saturation || 0
        });
        obj.filtersMap.brightness.setOptions({
          brightness: instance.brightness || 0
        });

        try {
          obj.applyFilters();
        } catch (err) {
          console.log(err);
        }

        layerAllObjects(fabricCanvas);
        fabricCanvas.renderAll();
      },
      options
    );
  }
  // Update the existing instance, if required
  else {
    // Ensure depth is updated
    obj.depth = instance.depth;
    obj.instance = instance;

    if (obj.url !== url) {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.onload = function() {
        obj.setElement(img);
        fabricCanvas.renderAll();
      };
      img.src = url;
      obj.url = url;
    }
    //

    const currentPosData = fabricToFdlTransform(obj, fabricCanvas);
    const hasAngleChanged = instance.angle !== currentPosData.angle;
    const hasOffsetChanged =
      instance.offset &&
      currentPosData.offset &&
      (instance.offset[0] !== currentPosData.offset[0] ||
        instance.offset[1] !== currentPosData.offset[1]);
    const hasScaleChanged =
      instance.scale &&
      currentPosData.scale &&
      (instance.scale[0] !== currentPosData.scale[0] ||
        instance.scale[1] !== currentPosData.scale[1]);
    const hasFlipChanged =
      instance.flipX !== currentPosData.flipX ||
      instance.flipY !== currentPosData.flipY;

    if (
      hasAngleChanged ||
      hasOffsetChanged ||
      hasScaleChanged ||
      hasFlipChanged
    ) {
      const options = fdlTranformToFabric(instance);
      obj.setOptions(options);
      if (options.left === null && options.top === null) {
        fabricCanvas.centerObject(obj);
      }
      obj.setCoords();
      updateCharacterControls(fabricCanvas);

      layerAllObjects(fabricCanvas);
    }

    // Update filters
    obj.filtersMap.gamma.setOptions({
      gamma: [instance.gammaR || 1, instance.gammaG || 1, instance.gammaB || 1]
    });
    obj.filtersMap.saturation.setOptions({
      saturation: instance.saturation || 0
    });
    obj.filtersMap.brightness.setOptions({
      brightness: instance.brightness || 0
    });
    try {
      obj.applyFilters();
    } catch (err) {
      console.log(err);
    }
    fabricCanvas.renderAll();
  }
}

function useDrawBackgrounds(backgrounds, fabricCanvas) {
  useEffect(() => {
    if (!fabricCanvas) {
      return;
    }
    if (!backgrounds) {
      return;
    }
    Object.keys(backgrounds).forEach(id => {
      drawInstance(id, backgrounds[id], "background", fabricCanvas);
    });
  }, [backgrounds, fabricCanvas]);
}

function useDrawProps(props, fabricCanvas) {
  useEffect(() => {
    if (!fabricCanvas) {
      return;
    }
    if (!props) {
      return;
    }
    Object.keys(props).forEach(id => {
      drawInstance(id, props[id], "prop", fabricCanvas);
    });
  }, [props, fabricCanvas]);
}

function useDrawCharacters(characters, fabricCanvas) {
  useEffect(() => {
    if (!fabricCanvas) {
      return;
    }
    if (!characters) {
      return;
    }
    Object.keys(characters).forEach(id => {
      drawInstance(id, characters[id], "character", fabricCanvas);
    });
  }, [characters, fabricCanvas]);
}

function useDrawCameraMoveArrows(cameraMove, fabricCanvas) {
  useEffect(() => {
    if (!fabricCanvas) {
      return;
    }

    const existingGroup = fabricCanvas
      .getObjects()
      .filter(obj => obj.id === "cameraMoveArrows");
    if (existingGroup.length > 0) {
      existingGroup.forEach(existingObj => {
        fabricCanvas.remove(existingObj);
      });
    }

    if (!cameraMove) {
      return;
    }

    const format = getFormat(fabricCanvas);

    const dollyPath =
      "M43.5443 9.92712L0 29.9282V50L39.8485 31.6549L43.5443 29.9282V40.0721L87 0H43.5443V9.92712Z";
    const panPath =
      "M177.5 9.02951L154.117 9.0295L133.916 9.29756L114.365 10.0157L94.8173 11.27L77.2186 13.0633L59.9507 15.2147L45.2893 17.7243L31.9329 20.7728L20.2017 23.9995L11.7313 27.4962L4.88594 31.1709L1.30507 35.0257L0 38.9695V42.7352V46.5L1.30507 42.5552L4.88594 38.7014L11.7313 34.9357L20.2017 31.44L31.9329 28.2123L45.2893 25.3438L59.9507 22.7443L77.2186 20.5028L94.8173 18.7104L114.365 17.4552L133.916 16.739L154.117 16.469H177.5V25.5L207.5 12.5L177.5 0V9.02951Z";
    const truckPath =
      "M181.719 8.609H169.394H4V17.128H169.394H181.719V25.737L212.078 12.869L181.719 0V8.609Z";
    const zoomPath =
      "M34.2825 21.0734H4L4.00004 41.9266H34.2825V63L62 31.8219L34.2825 0V21.0734Z";

    function moveXCenter(obj, padding = 10) {
      obj.left = format[0] / 2;
    }
    function moveXLeft(obj, padding = 10) {
      obj.left = obj.getBoundingRect().width / 2 + padding;
    }

    function moveXRight(obj, padding = 10) {
      obj.left = format[0] - obj.getBoundingRect().width / 2 - padding;
    }
    function moveYCenter(obj, padding = 10) {
      obj.top = format[1] / 2;
    }
    function moveYTop(obj, padding = 10) {
      obj.top = obj.getBoundingRect().height / 2 + padding;
    }

    function moveYBottom(obj, padding = 10) {
      obj.top = format[1] - obj.getBoundingRect().height / 2 - padding;
    }
    function createTruckArrows(options = {}) {
      const arrow1 = createArrowFromPath(truckPath, options);
      arrow1.scaleToWidth(500);
      moveXCenter(arrow1);
      moveYTop(arrow1);

      const arrow2 = createArrowFromPath(truckPath, options);
      arrow2.scaleToWidth(500);
      moveXCenter(arrow2);
      moveYBottom(arrow2);

      return [arrow1, arrow2];
    }

    function createPedestalArrows(options = {}) {
      const arrow1 = createArrowFromPath(truckPath, options);
      arrow1.rotate(-90);
      arrow1.scaleToHeight(500);
      moveXLeft(arrow1);
      moveYCenter(arrow1);

      const arrow2 = createArrowFromPath(truckPath, options);
      arrow2.rotate(-90);
      arrow2.scaleToHeight(500);
      moveXRight(arrow2);
      moveYCenter(arrow2);

      return [arrow1, arrow2];
    }

    function createPanArrows(options = {}) {
      const arrow1 = createArrowFromPath(panPath, { flipY: true, ...options });
      arrow1.scaleToWidth(500);
      moveXCenter(arrow1);
      moveYTop(arrow1);

      const arrow2 = createArrowFromPath(panPath, options);
      arrow2.scaleToWidth(500);
      moveXCenter(arrow2);
      moveYBottom(arrow2);

      return [arrow1, arrow2];
    }
    function createTiltArrows(options = {}) {
      const arrow1 = createArrowFromPath(panPath, { flipY: true, ...options });
      arrow1.rotate(-90);
      arrow1.scaleToHeight(500);
      moveXLeft(arrow1);
      moveYCenter(arrow1);

      const arrow2 = createArrowFromPath(panPath, options);
      arrow2.rotate(-90);
      arrow2.scaleToHeight(500);
      moveXRight(arrow2);
      moveYCenter(arrow2);

      return [arrow1, arrow2];
    }

    function createZoomArrows(rotation = 0) {
      const arrow1 = createArrowFromPath(zoomPath);
      arrow1.rotate(45 + rotation);
      arrow1.scaleToWidth(150);
      moveXLeft(arrow1, -20);
      moveYTop(arrow1, -20);

      const arrow2 = createArrowFromPath(zoomPath);
      arrow2.rotate(45 + rotation + 90);
      arrow2.scaleToWidth(150);
      moveXRight(arrow2, -20);
      moveYTop(arrow2, -20);

      const arrow3 = createArrowFromPath(zoomPath);
      arrow3.rotate(45 + rotation + 180);
      arrow3.scaleToWidth(150);
      moveXRight(arrow3, -20);
      moveYBottom(arrow3, -20);

      const arrow4 = createArrowFromPath(zoomPath);
      arrow4.rotate(45 + rotation + 270);
      arrow4.scaleToWidth(150);
      moveXLeft(arrow4, -20);
      moveYBottom(arrow4, -20);

      return [arrow1, arrow2, arrow3, arrow4];
    }

    function createDollyArrows(rotation = 0) {
      const vPadding = 50;
      const arrow1 = createArrowFromPath(dollyPath, { flipY: true });
      arrow1.rotate(rotation);
      arrow1.scaleToWidth(150);
      moveXLeft(arrow1);
      moveYTop(arrow1, vPadding);

      const arrow2 = createArrowFromPath(dollyPath, {
        flipY: true,
        flipX: true
      });
      arrow2.rotate(rotation);
      arrow2.scaleToWidth(150);
      moveXRight(arrow2);
      moveYTop(arrow2, vPadding);

      const arrow3 = createArrowFromPath(dollyPath, {
        flipX: true
      });
      arrow3.rotate(rotation);
      arrow3.scaleToWidth(150);
      moveXRight(arrow3);
      moveYBottom(arrow3, vPadding);

      const arrow4 = createArrowFromPath(dollyPath);
      arrow4.rotate(rotation);
      arrow4.scaleToWidth(150);
      moveXLeft(arrow4);
      moveYBottom(arrow4, vPadding);

      return [arrow1, arrow2, arrow3, arrow4];
    }

    const defaultCameraArrowStyle = {
      fill: "#f8b133",
      stroke: "#1c1c1c",
      strokeWidth: 1
    };
    function createArrowFromPath(path, options = {}) {
      const arrow = new fabric.Path(`L ${path} Z`, {
        ...defaultCameraArrowStyle,
        originX: "center",
        originY: "center",
        ...options
      });
      return arrow;
    }

    let arrowObjs = [];
    switch (cameraMove) {
      case "Pan Right":
        arrowObjs.push(...createPanArrows());
        break;
      case "Pan Left":
        arrowObjs.push(...createPanArrows({ flipX: true }));
        break;
      case "Tilt Up":
        arrowObjs.push(...createTiltArrows());
        break;
      case "Tilt Down":
        arrowObjs.push(...createTiltArrows({ flipX: true }));
        break;
      case "Truck Right":
        arrowObjs.push(...createTruckArrows());
        break;
      case "Truck Left":
        arrowObjs.push(...createTruckArrows({ flipX: true }));
        break;
      case "Pedestal Up":
        arrowObjs.push(...createPedestalArrows());
        break;
      case "Pedestal Down":
        arrowObjs.push(...createPedestalArrows({ flipX: true }));
        break;
      case "Zoom In":
        arrowObjs.push(...createZoomArrows());
        break;
      case "Zoom Out":
        arrowObjs.push(...createZoomArrows(180));
        break;
      case "Dolly In":
        arrowObjs.push(...createDollyArrows());
        break;
      case "Dolly Out":
        arrowObjs.push(...createDollyArrows(180));
        break;
      default:
        break;
    }

    const group = new fabric.Group(arrowObjs);
    group.selectable = false;
    group.evented = false;

    group.id = "cameraMoveArrows";
    fabricCanvas.add(group);
  }, [cameraMove, fabricCanvas]);
}

function useDrawDisplayGuides(fabricCanvas) {
  useEffect(() => {
    if (!fabricCanvas) {
      return;
    }
    const style1 = {
      strokeDashArray: [10, 10],
      stroke: "#1c1c1c",
      opacity: 0.5
    };
    const style2 = {
      strokeDashArray: [10, 10],
      stroke: "#eaeaea",
      opacity: 0.5
    };

    const format = getFormat(fabricCanvas);

    const xLength = format[0];
    const yLength = format[1];
    const yIncr = yLength / 3;
    const xIncr = xLength / 3;

    const lineCoords = [
      // Horizontal lines
      [0, 0, xLength, 0],
      [0, yIncr, xLength, yIncr],
      [0, yIncr * 2, xLength, yIncr * 2],
      [0, yLength, xLength, yLength],
      // Vertical lines
      [0, 0, 0, yLength],
      [xIncr, 0, xIncr, yLength],
      [xIncr * 2, 0, xIncr * 2, yLength],
      [xLength, 0, xLength, yLength]
    ];

    const lines1 = lineCoords.map(coords => {
      return new fabric.Line(coords, style1);
    });
    const lines2 = lineCoords.map(coords => {
      return new fabric.Line(
        coords.map(c => c + 1),
        style2
      );
    });
    const lines = lines1.concat(lines2);

    const resText = new fabric.Text(`${xLength}x${yLength}`);
    const textOptions = {
      fontSize: 32,
      color: "#5b5b5b",
      opacity: 0.5,
      left: xLength - resText.width,
      top: yLength
    };
    resText.setOptions(textOptions);

    const allObjects = [...lines, resText];

    const group = new fabric.Group(allObjects);
    group.selectable = false;
    group.evented = false;

    group.id = "displayGrid";
    fabricCanvas.add(group);
  }, [fabricCanvas]);

  useEffect(() => {
    if (!fabricCanvas) {
      return;
    }

    const maskStyleOptions = {
      fill: "#1c1c1c",
      opacity: 1
    };
    const maskThickness = 500;

    const format = getFormat(fabricCanvas);

    const maskObjs = [
      new fabric.Rect({
        ...maskStyleOptions,
        left: -10,
        top: 0,
        width: maskThickness + format[0] + 10,
        height: -maskThickness
      }),
      new fabric.Rect({
        ...maskStyleOptions,
        left: format[0],
        top: -10,
        width: maskThickness,
        height: maskThickness + format[1] + 10
      }),
      new fabric.Rect({
        ...maskStyleOptions,
        left: format[0] + 10,
        top: format[1],
        width: -maskThickness - format[0] - 10,
        height: maskThickness
      }),
      new fabric.Rect({
        ...maskStyleOptions,
        left: 0,
        top: format[1] + 10,
        width: -maskThickness,
        height: -maskThickness - format[1] - 10
      })
    ];

    const group = new fabric.Group(maskObjs);
    group.selectable = false;
    group.evented = false;

    group.id = "displayMask";
    fabricCanvas.add(group);

    // resetView(fabricCanvas);
  }, [fabricCanvas]);
}

function resetView(
  fabricCanvas,
  sidebar_width,
  appbar_height,
  paddingFactor = 1.0
) {
  if (!fabricCanvas) {
    return;
  }
  // Current canvas resolution on the current screen
  const canvasX = window.innerWidth - sidebar_width;
  const canvasY = window.innerHeight - appbar_height;

  const format = getFormat(fabricCanvas);

  // Target resolution: the display grid plus padding
  const targetX = format[0] * paddingFactor;
  const targetY = format[1] * paddingFactor;

  const zoomFactorX = canvasX / targetX;
  const zoomFactorY = canvasY / targetY;

  const zoomFactor = Math.min(zoomFactorX, zoomFactorY);

  if (
    zoomFactor !== fabricCanvas.viewportTransform[0] ||
    zoomFactor !== fabricCanvas.viewportTransform[3]
  ) {
    fabricCanvas.setZoom(zoomFactor);
  }

  const zoomedGridWidth = format[0] * fabricCanvas.viewportTransform[0];
  const zoomedGridHeight = format[1] * fabricCanvas.viewportTransform[3];

  const newLeft = (fabricCanvas.width - zoomedGridWidth) / 2;
  const newTop = (fabricCanvas.height - zoomedGridHeight) / 2;
  if (
    fabricCanvas.viewportTransform[4] !== newLeft ||
    fabricCanvas.viewportTransform[5] !== newTop
  ) {
    fabricCanvas.viewportTransform[4] = newLeft;
    fabricCanvas.viewportTransform[5] = newTop;
  }

  fabricCanvas.getObjects().forEach(obj => obj.setCoords());

  resizeObjectControls(fabricCanvas);
}

function setObjectControlSize(obj, fabricCanvas) {
  obj.cornerSize = 40 * fabricCanvas.getZoom();
  obj.rotatingPointOffset = 100 * fabricCanvas.getZoom();
}

function resizeObjectControls(fabricCanvas) {
  fabricCanvas.getObjects().forEach(obj => {
    setObjectControlSize(obj, fabricCanvas);
  });
}

function useRemoveUnknownAssets(fdl, fabricCanvas) {
  if (!fabricCanvas) {
    return;
  }
  const validIds = [
    ...Object.keys(fdl.backgrounds || {}),
    ...Object.keys(fdl.props || {}),
    ...Object.keys(fdl.characters || {}),
    "displayGrid",
    "displayMask",
    "cameraMoveArrows",
    "rotateControls",
    "rotateMarker",
    "camHeightControls",
    "camHeightMarker"
  ];

  const existingObjs = [...fabricCanvas.getObjects()];
  existingObjs.forEach(obj => {
    if (!obj.id || !validIds.includes(obj.id)) {
      console.log(
        "WARNING: Unknown asset detected",
        [...[obj.id]],
        [...validIds]
      );
      fabricCanvas.remove(obj);
    }
  });
  fabricCanvas.renderAll();
}

function layerAllObjects(fabricCanvas) {
  if (!fabricCanvas) {
    return;
  }
  const objs = fabricCanvas.getObjects();

  const defaultDepths = { characters: 90, props: 50, backgrounds: 10 };
  getSelectableObjects(fabricCanvas)
    .sort((a, b) => {
      if (a.depth === b.depth) {
        return a.id > b.id ? 1 : -1;
      } else if (a.depth > b.depth) {
        return 1;
      } else {
        return -1;
      }
    })
    .forEach(obj => {
      const depth =
        obj.depth === undefined || obj.depth === null
          ? defaultDepths[obj.assetType]
          : obj.depth;
      if (objs.indexOf(obj) !== depth) {
        obj.moveTo(depth);
      }
    });

  getNonSelectableObjects(fabricCanvas).forEach(obj => {
    fabricCanvas.bringToFront(obj);
  });

  getCharacterControlObjects(fabricCanvas).forEach(obj => {
    fabricCanvas.bringToFront(obj);
  });

  getCharacterControlMarkerObjects(fabricCanvas).forEach(obj => {
    fabricCanvas.bringToFront(obj);
  });

  fabricCanvas.renderAll();
}

function getSelectableObjects(fabricCanvas) {
  const objs = fabricCanvas
    .getObjects()
    .filter(
      obj =>
        obj.id !== "displayMask" &&
        obj.id !== "displayGrid" &&
        obj.id !== "cameraMoveArrows"
    );
  return objs;
}

function getNonSelectableObjects(fabricCanvas) {
  const objs = fabricCanvas
    .getObjects()
    .filter(
      obj =>
        obj.id === "displayMask" ||
        obj.id === "displayGrid" ||
        obj.id === "cameraMoveArrows" ||
        obj.id === "rotateControls" ||
        obj.id === "rotateMarker" ||
        obj.id === "camHeightControls" ||
        obj.id === "camHeightMarker"
    );
  return objs;
}

function getCharacterControlObjects(fabricCanvas) {
  const objs = fabricCanvas
    .getObjects()
    .filter(
      obj => obj.id === "rotateControls" || obj.id === "camHeightControls"
    );
  return objs;
}
function getCharacterControlMarkerObjects(fabricCanvas) {
  const objs = fabricCanvas
    .getObjects()
    .filter(obj => obj.id === "rotateMarker" || obj.id === "camHeightMarker");
  return objs;
}

function useDrawFrame(fdl, fabricCanvas) {
  useDrawBackgrounds(fdl.backgrounds, fabricCanvas);
  useDrawProps(fdl.props, fabricCanvas);
  useDrawCharacters(fdl.characters, fabricCanvas);
  useDrawCameraMoveArrows(fdl.cameraMove, fabricCanvas);
  useDrawDisplayGuides(fabricCanvas);
  useRemoveUnknownAssets(fdl, fabricCanvas);
  updateCharacterControls(fabricCanvas);
  layerAllObjects(fabricCanvas);
}

function useUpdateSelection(fabricCanvas) {
  const selection = useSelector(state => state.frameEditor.selection);

  useEffect(() => {
    if (!fabricCanvas) {
      return;
    }
    if (selection) {
      fabricCanvas.getObjects().forEach(obj => {
        if (obj.id === selection) {
          fabricCanvas.setActiveObject(obj);
        }
      });
      fabricCanvas.renderAll();
    } else {
      fabricCanvas.discardActiveObject();
      fabricCanvas.renderAll();
    }
  }, [selection, fabricCanvas]);
}

function useResetView(fabricCanvas, sidebar_width, appbar_height) {
  const dispatch = useDispatch();
  const resetViewObj = useSelector(state => state.frameEditor.resetView);

  useEffect(() => {
    if (!fabricCanvas) {
      return;
    }
    if (!resetViewObj || !resetViewObj.shouldReset) {
      return;
    }
    resetView(
      fabricCanvas,
      sidebar_width,
      appbar_height,
      resetViewObj.resetValue
    );

    dispatch({
      type: "RESET_VIEW",
      shouldReset: false,
      resetValue: 1.0
    });
  }, [resetViewObj, fabricCanvas, dispatch, sidebar_width, appbar_height]);
}

function useDisplayGridOfThirds(fabricCanvas) {
  const displayGridOfThirdsValue = useSelector(
    state => state.frameEditor.displayGridOfThirds
  );

  useEffect(() => {
    if (!fabricCanvas) {
      return;
    }
    const obj = fabricCanvas
      .getObjects()
      .filter(obj => obj.id === "displayGrid")
      .pop();
    obj.visible = displayGridOfThirdsValue;
    fabricCanvas.renderAll();
  }, [displayGridOfThirdsValue, fabricCanvas]);
}

function useDisplayFrameMask(fabricCanvas) {
  const displayFrameMaskValue = useSelector(
    state => state.frameEditor.displayFrameMask
  );

  useEffect(() => {
    if (!fabricCanvas) {
      return;
    }
    const obj = fabricCanvas
      .getObjects()
      .filter(obj => obj.id === "displayMask")
      .pop();
    obj.visible = displayFrameMaskValue;
    fabricCanvas.renderAll();
  }, [displayFrameMaskValue, fabricCanvas]);
}

export default function FrameCanvas(props) {
  const { noSideBar, noAppBar, initialZoom } = props;

  const fdl = useSelector(state => state.fdl.present);

  const sessionIds = useSelector(state => state.session.sessionIds);
  const frameId = sessionIds ? sessionIds.frameId : null;
  const storyboardId = sessionIds ? sessionIds.storyboardId : null;

  const { value: storyboard, setDoFetch: fetchStoryboard } = useStoryboard(
    storyboardId
  );
  React.useEffect(() => {
    if (storyboardId) {
      fetchStoryboard(true);
    }
  }, [storyboardId]);

  const canvasRef = useRef(null);

  const cssStyle = getComputedStyle(document.documentElement);
  const sidebar_width = noSideBar
    ? 0
    : parseInt(cssStyle.getPropertyValue("--outliner-width"));
  const appbar_height = noAppBar
    ? 0
    : parseInt(cssStyle.getPropertyValue("--appbar-height"));

  const fabricCanvas = useFabricCanvas(
    canvasRef,
    frameId,
    sidebar_width,
    appbar_height,
    storyboard
  );

  useDrawFrame(fdl, fabricCanvas);
  useUpdateSelection(fabricCanvas);
  useResetView(fabricCanvas, sidebar_width, appbar_height);
  useDisplayGridOfThirds(fabricCanvas);
  useDisplayFrameMask(fabricCanvas);

  useEffect(() => {
    resetView(fabricCanvas, sidebar_width, appbar_height);
  }, [fabricCanvas, initialZoom, sidebar_width, appbar_height]);

  return (
    <div className="frame-canvas-container">
      <canvas ref={canvasRef} style={{ position: "absolute" }} />
    </div>
  );
}
