import React from "react";
import "./PreviewImage.scss";

export default function PreviewImage(props) {
  const { url } = props;
  const noUrlClass = !url ? "no-url" : "";
  return (
    <div className={`preview-image ${noUrlClass}`}>
      <img src={url || ""} alt="" />
    </div>
  );
}
