import React from "react";
import { useSelector } from "react-redux";
import OutlinerItem from "../../Outliner/OutlinerItem";
import PropIcon from "@material-ui/icons/CardTravel";
import { Typography } from "@material-ui/core";
import { setRedirect } from "../../Utility/Redirect/hooks";
import { withRouter } from "react-router";

function PropOutliner(props) {
  const sessionIds = useSelector(state => state.session.sessionIds);
  const projectId = sessionIds ? sessionIds.projectId : null;

  const url = `/project/${projectId}/props`;

  return (
    <div>
      <OutlinerItem
        onClick={() => {
          setRedirect({ to: url });
        }}
        selected={props.match.url === url}
        selectable={true}
      >
        <PropIcon />
        <Typography className="outliner-text">Props</Typography>
      </OutlinerItem>
    </div>
  );
}

export default withRouter(PropOutliner);
