import React, { useEffect } from "react";
import {
  InputBase,
  Link,
  Typography,
  CircularProgress
} from "@material-ui/core";
import PreviewImage from "./../Gallery/PreviewImage";
import SearchIcon from "@material-ui/icons/Search";
import Backend from "../../../backend";
import "./PixabayGallery.scss";

function useFetchImages(searchString, searchForIsolatedOnly) {
  const [results, setResults] = React.useState(null);
  const [isFetching, setIsFetching] = React.useState(false);

  useEffect(() => {
    if (searchString) {
      let _searchString = searchString;
      if (searchForIsolatedOnly) {
        _searchString = searchString + " isolated";
      }
      setResults(null);
      setIsFetching(true);
      Backend.pixabaySearch(_searchString)
        .then(results => setResults(results))
        .catch(error => console.log("ERROR:", error))
        .finally(() => setIsFetching(false));
    }
  }, [searchString, searchForIsolatedOnly]);

  return { results, setResults, isFetching };
}

function Search(props) {
  const { setValue, disabled } = props;
  return (
    <>
      <div className="search-row">
        <SearchIcon className="search-icon" />
        <InputBase
          className="search-input"
          disabled={disabled}
          placeholder="Type and press enter to search"
          onKeyDown={event => {
            if (event.key === "Enter") {
              setValue(event.target.value);
            }
          }}
        />
      </div>

      <Typography className="pixabay-link">
        Search results from{" "}
        <Link
          href="https://pixabay.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          pixabay.com
        </Link>
      </Typography>
    </>
  );
}

export default function PixabayGallery(props) {
  const { onImageClicked, searchForIsolatedOnly } = props;
  const [searchString, setSearchString] = React.useState("");

  const [hasSearchedOnce, setHasSearchedOnce] = React.useState(false);
  const [inProgress, setInProgress] = React.useState(false);

  const { results, isFetching } = useFetchImages(
    searchString,
    searchForIsolatedOnly
  );
  const [isProcessingImage, setIsProcessingImage] = React.useState(false);

  useEffect(() => {
    if (isFetching || isProcessingImage) {
      setInProgress(true);
    } else {
      setInProgress(false);
    }
  }, [isFetching, isProcessingImage]);

  useEffect(() => {
    if (searchString) {
      setHasSearchedOnce(true);
    }
  }, [searchString]);

  return (
    <div className="asset-gallery scrollable-asset-gallery">
      <Search
        value={searchString}
        setValue={setSearchString}
        disabled={inProgress}
      />
      {inProgress && (
        <div className="pixabay-progress-container">
          <CircularProgress className="pixabay-progress" />
        </div>
      )}
      <div className="asset-grid">
        {!hasSearchedOnce ? (
          <Typography variant="body1">
            Type and press enter to search for images
          </Typography>
        ) : results &&
          results.hits &&
          results.hits.length <= 0 &&
          !isFetching ? (
          <Typography variant="body1">No images found</Typography>
        ) : (
          results &&
          results.hits &&
          results.hits.map(image => {
            return (
              <div
                className="asset-grid-item"
                onClick={() => {
                  setIsProcessingImage(true);
                  onImageClicked(image).finally(() =>
                    setIsProcessingImage(false)
                  );
                }}
                key={image.id}
              >
                <PreviewImage url={image.previewURL} text={image.tags} />
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}
