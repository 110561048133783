import React from "react";
import { useFrames } from "./hooks";
import FrameCard from "./FrameCard";
import FrameCardActions from "./FrameCardActions";
import NewFrameCard from "./NewFrameCard";
import DNDProvider from "./../DND/DNDProvider";
import DNDItem from "./../DND/DNDItem";
import { setRedirect } from "../Utility/Redirect/hooks";

import { useMoveCard } from "./../DND/hooks";
import "./StoryboardWall.scss";

export default function StoryboardWall(props) {
  const { storyboardId } = props;
  const {
    frames,
    orderedFrameIds,
    setOrderedFrameIds,
    setShouldFetch,
    hasFetched
  } = useFrames(storyboardId);

  const moveCard = useMoveCard(
    orderedFrameIds,
    setOrderedFrameIds,
    storyboardId
  );

  return hasFetched ? (
    <DNDProvider>
      <div
        className="storyboard-wall-grid"
        onContextMenu={e => e.preventDefault()}
      >
        {orderedFrameIds.map((frameId, index) => {
          const frame = frames[frameId];

          return (
            <div className="frame-card-and-actions" key={frame.id}>
              <DNDItem
                id={frame.id}
                index={index}
                moveCard={moveCard}
                draggable={true}
              >
                <FrameCard frame={frame} showTitle={true} />
              </DNDItem>

              <FrameCardActions
                frameId={frame.id}
                onActionComplete={() => setShouldFetch(true)}
              />
            </div>
          );
        })}
        <DNDItem
          id={null}
          index={frames.length}
          moveCard={moveCard}
          draggable={false}
        >
          <NewFrameCard
            storyboardId={storyboardId}
            onNewFrameCreated={frameId =>
              setRedirect({ to: `/frame/${frameId}` })
            }
          />
        </DNDItem>
      </div>
    </DNDProvider>
  ) : null;
}
