import Backend from "../../../backend";
import { useFetch } from "./../../Utility/hooks";

export function useProps(projectId) {
  const queryFunction = async () => {
    if (!projectId) {
      return [];
    }
    return Backend.__getProps(projectId);
  };
  return useFetch(queryFunction, []);
}
