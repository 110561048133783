import React from "react";
import { useDispatch, useSelector } from "react-redux";

import SliderProperty from "./../../Outliner/SliderProperty";
import Toolbox from "./../../Toolbox/Toolbox";
import ToolboxItem from "./../../Toolbox/ToolboxItem";
import DeleteIcon from "@material-ui/icons/Delete";
import OutlinerItem from "./../../Outliner/OutlinerItem";
import { Typography } from "@material-ui/core";

export default function CharacterProperties(props) {
  const { id } = props;
  const fdl = useSelector(state => state.fdl.present);
  const dispatch = useDispatch();

  const properties = fdl.characters[id];

  return (
    <>
      <OutlinerItem selectable={false}>
        <Typography className="outliner-header" noWrap>
          Properties: <span className="highlight">{properties.name}</span>
        </Typography>
      </OutlinerItem>
      <SliderProperty
        min={0}
        max={100}
        step={0.1}
        marks={[
          { value: 0, label: "back" },
          { value: 100, label: "front" }
        ]}
        title="Depth"
        value={properties.depth || 90}
        defaultValue={90}
        saveValue={value => {
          dispatch({
            type: "UPDATE_CHARACTER",
            charId: id,
            updates: {
              depth: value
            }
          });
        }}
      />
      <SliderProperty
        min={-1}
        max={1}
        step={0.01}
        marks={[{ value: 0 }]}
        title="Saturation"
        value={properties.saturation}
        defaultValue={0}
        saveValue={value => {
          dispatch({
            type: "UPDATE_CHARACTER",
            charId: id,
            updates: {
              saturation: value
            }
          });
        }}
      />
      <SliderProperty
        min={-1}
        max={1}
        step={0.01}
        marks={[{ value: 0 }]}
        title="Brightness"
        value={properties.brightness}
        defaultValue={0}
        saveValue={value => {
          dispatch({
            type: "UPDATE_CHARACTER",
            charId: id,
            updates: {
              brightness: value
            }
          });
        }}
      />
      <SliderProperty
        min={0.01}
        max={2} // real max is 2.2, but we shorten the range so our default value is the linear mid-point
        step={0.01}
        marks={[{ value: 1 }]}
        title="Red (gamma)"
        value={properties.gammaR}
        defaultValue={1}
        saveValue={value => {
          dispatch({
            type: "UPDATE_CHARACTER",
            charId: id,
            updates: {
              gammaR: value
            }
          });
        }}
      />
      <SliderProperty
        min={0.01}
        max={2} // real max is 2.2, but we shorten the range so our default value is the linear mid-point
        step={0.01}
        marks={[{ value: 1 }]}
        title="Green (gamma)"
        value={properties.gammaG}
        defaultValue={1}
        saveValue={value => {
          dispatch({
            type: "UPDATE_CHARACTER",
            charId: id,
            updates: {
              gammaG: value
            }
          });
        }}
      />
      <SliderProperty
        min={0.01}
        max={2} // real max is 2.2, but we shorten the range so our default value is the linear mid-point
        step={0.01}
        marks={[{ value: 1 }]}
        title="Blue (gamma)"
        value={properties.gammaB}
        defaultValue={1}
        saveValue={value => {
          dispatch({
            type: "UPDATE_CHARACTER",
            charId: id,
            updates: {
              gammaB: value
            }
          });
        }}
      />
      <Toolbox>
        <ToolboxItem tooltip="Delete this instance of this character">
          <DeleteIcon
            onClick={() =>
              dispatch({
                type: "DELETE_CHARACTER",
                charId: id
              })
            }
          />
        </ToolboxItem>
      </Toolbox>
    </>
  );
}
