import React from "react";
import { DndProvider as ReactDndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import TouchBackend from "react-dnd-touch-backend";
import MultiBackend, { TouchTransition } from "react-dnd-multi-backend";

import "./DND.scss";

const HTML5toTouch = {
  backends: [
    {
      backend: HTML5Backend
    },
    {
      backend: TouchBackend,
      options: {
        ignoreContextMenu: true,
        delayTouchStart: 200
      },
      preview: true,
      transition: TouchTransition
    }
  ]
};

export default function DNDProvider(props) {
  return (
    <ReactDndProvider backend={MultiBackend} options={HTML5toTouch}>
      {props.children}
    </ReactDndProvider>
  );
}
