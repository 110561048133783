import React from "react";

import { Tooltip, Avatar } from "@material-ui/core";

export default function UserBadge(props) {
  const { user } = props;
  return (
    <div className="user-badge">
      <Tooltip title={user.name || user.email} placement="right-start">
        <Avatar alt={user.name || user.email || ""} src={user.photoURL} />
      </Tooltip>
    </div>
  );
}
