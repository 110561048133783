import React from "react";
import { useSelector } from "react-redux";
import StoryboardPdf from "../components/Storyboards/StoryboardPdf";
import { useInitSessionIdsFromParams } from "../components/Utility/Session/hooks";

export default function StoryboardPdfPage(props) {
  useInitSessionIdsFromParams(props);

  const sessionIds = useSelector(state => state.session.sessionIds);
  const projectId = sessionIds ? sessionIds.projectId : null;
  const storyboardId = sessionIds ? sessionIds.storyboardId : null;

  return storyboardId && projectId ? (
    <StoryboardPdf projectId={projectId} storyboardId={storyboardId} />
  ) : (
    <div />
  );
}
