import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Modal } from "@material-ui/core";

import { useFrames } from "../Storyboards/hooks";

import PreviewImage from "../Storyboards/PreviewImage";

import { Tooltip } from "@material-ui/core";
import CreateFrameIcon from "@material-ui/icons/Add";
import DuplicateFrameIcon from "@material-ui/icons/FileCopy";

import { setRedirect } from "./../Utility/Redirect/hooks";

import "./FrameReelDialog.scss";
import Backend from "../../backend";

export default function FrameReelDialog(props) {
  const { isOpen } = props;

  const sessionIds = useSelector(state => state.session.sessionIds);
  const storyboardId = sessionIds ? sessionIds.storyboardId : null;
  const currentFrameId = sessionIds ? sessionIds.frameId : null;

  const { frames, orderedFrameIds, setShouldFetch } = useFrames(storyboardId);

  return (
    <Modal
      open={isOpen}
      className={`frame-reel-modal`}
      BackdropProps={{ invisible: true }}
    >
      <div className={`frame-reel-container`} id="frame-reel-container">
        <div className="frames">
          {orderedFrameIds.map(id => {
            return currentFrameId === id ? (
              <div
                className="frame current-frame"
                id="current-frame"
                key={id}
                ref={input => {
                  let reel = document.getElementById("frame-reel-container");
                  if (input && reel) {
                    reel.scrollLeft = input.offsetLeft - window.innerWidth / 2;
                  }
                }}
              >
                <PreviewImage url={frames[id].thumbnail} />
                <div className="current-frame-actions">
                  <Tooltip title="Duplicate frame">
                    <DuplicateFrameIcon
                      className="action-icon"
                      onClick={() => {
                        const relativeToFrameId = currentFrameId;
                        Backend.duplicateFrame(
                          currentFrameId,
                          relativeToFrameId
                        )
                          .then(frameId =>
                            setRedirect({ to: `/frame/${frameId}` })
                          )
                          .then(() => setShouldFetch(true));
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="Create frame">
                    <CreateFrameIcon
                      className="action-icon"
                      onClick={() => {
                        const relativeToFrameId = currentFrameId;
                        Backend.createFrame(storyboardId, {}, relativeToFrameId)
                          .then(frameId =>
                            setRedirect({ to: `/frame/${frameId}` })
                          )
                          .then(() => setShouldFetch(true));
                      }}
                    />
                  </Tooltip>
                </div>
              </div>
            ) : (
              <div className="frame" key={id}>
                <Link className="frame-card-a" to={`/frame/${id}`}>
                  <PreviewImage url={frames[id].thumbnail} />
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
}
