import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Backend from "../../backend";
import _ from "lodash";
import store from "./../../store";
import { useFetch } from "./../Utility/hooks";
import { ActionCreators } from "redux-undo";

export function useFrame(frameId) {
  const queryFunction = async () => {
    if (!frameId) {
      return [];
    }
    return Backend.getFrame(frameId);
  };
  return useFetch(queryFunction, []);
}

export function useMostRecentFrames(limit) {
  const getMostRecentFrames = async => Backend.getMostRecentFrames(limit);
  return useFetch(getMostRecentFrames, []);
}

export function useSyncLocalFdlToServer() {
  const sessionIds = useSelector(state => state.session.sessionIds);
  const fdl = useSelector(state => state.fdl.present);
  const pastFdl = useSelector(state => state.fdl.past);
  const prevFdl = pastFdl[pastFdl.length - 1];

  const throttledSaveFDL = useRef(_.throttle(Backend.saveFDL, 2000), []);

  useEffect(() => {
    store.dispatch(ActionCreators.clearHistory());
  }, [sessionIds]);

  useEffect(() => {
    const frameId = (sessionIds && sessionIds.frameId) || null;
    if (!frameId) {
      return;
    }
    if (fdl.initialState) {
      return;
    }

    if (fdl.loadedState) {
      return;
    }

    if (!prevFdl) {
      return;
    }

    const fdlToSave = { ...fdl };
    delete fdlToSave.initialState;

    throttledSaveFDL.current(frameId, fdlToSave);
    // return () => throttledSaveFDL.current.flush();
  }, [fdl]);
}

export function useSyncServerFdlToLocalState() {
  const dispatch = useDispatch();
  const sessionIds = useSelector(state => state.session.sessionIds);

  const frameId = sessionIds ? sessionIds.frameId : null; //TODO: Make sure sessionIds is initialised properly

  useEffect(() => {
    // First we define a call back function. This will take fdl received
    // from the server and dispatch it to the local redux store

    const setFdl = serverFdl => {
      const state = store.getState();
      const localFdl = (state && state.fdl && state.fdl.present) || {};

      if (serverFdl.timestamp <= localFdl.timestamp) {
        return;
      }

      const candidateFdl = { ...localFdl, ...serverFdl };
      const currentLocalFdl = { ...localFdl };

      // Ignore initialState flag
      delete candidateFdl.initialState;
      delete currentLocalFdl.initialState;
      delete candidateFdl.timestamp;
      delete currentLocalFdl.timestamp;

      if (!_.isEqual(currentLocalFdl, candidateFdl)) {
        dispatch({
          type: "LOAD_FRAME",
          fdl: serverFdl
        });
      }
    };

    if (frameId) {
      // Register the callback for when a frames fdl changes in firebase
      const unsubscribe = Backend.addFDLCallback(frameId, setFdl);

      // Return a function to deregister the callback
      return () => {
        unsubscribe();
        dispatch({
          type: "CLEAR_FRAME"
        });
        dispatch({
          type: "SET_SELECTION",
          id: null
        });
      };
    }
  }, [frameId, dispatch]);
}

export function useFDLWithoutSync(frameId) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!frameId) {
      return;
    }
    Backend.getFDL(frameId).then(fdl => {
      dispatch({
        type: "LOAD_FRAME",
        fdl: fdl
      });
    });
  }, [frameId, dispatch]);
}
