import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAllProjects } from "./../components/Projects/hooks";
import PreviewImage from "../components/Storyboards/PreviewImage";
import Backend from "./../backend";

import "./AdminProjects.scss";

export default function AdminProjectsPage(props) {
  const { value: projects, setDoFetch } = useAllProjects();

  useEffect(() => {
    setDoFetch(true);
  }, [setDoFetch]);

  const [users, setUsers] = React.useState({});
  useEffect(() => {
    projects.forEach(project => {
      Backend.getUserInfo(project.creator).then(user => {
        setUsers(prevState => {
          return { ...prevState, [project.creator]: user };
        });
      });
    });
  }, [projects]);

  projects
    .sort((p1, p2) => (p1.creation_time < p2.creation_time ? 1 : -1))
    .sort((p1, p2) =>
      ((p1._updated && p1._updated.seconds) || 0) <
      ((p2._updated && p2._updated.seconds) || 0)
        ? 1
        : -1
    );

  return (
    <div className="admin-projects-page">
      <div className="table">
        {(projects || []).map(project => {
          return (
            <React.Fragment key={project.id}>
              <div className="cell">
                <Link to={`/project/${project.id}`}>
                  <PreviewImage url={project.thumbnail} />
                </Link>
              </div>
              <div className="cell">
                {(users[project.creator] && users[project.creator].email) ||
                  "..."}
              </div>
              <div className="cell">
                {project._updated
                  ? new Date(project._updated.seconds * 1000).toUTCString()
                  : "?"}
              </div>
              <div className="cell">
                <Link to={`/project/${project.id}`}>{project.name}</Link>
              </div>
              <div className="cell">
                {project.archived ? "ARCHIVED" : "LIVE"}
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}
