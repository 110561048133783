import React from "react";
import { useStoryboard, useFrames } from "./hooks";
import Typography from "@material-ui/core/Typography";
import { useProject } from "../Projects/hooks";
import FrameCard from "./FrameCard";

import "./StoryboardPdf.scss";

export default function StoryboardPdf(props) {
  const { value: project, setDoFetch: fetchProject } = useProject(
    props.projectId
  );
  React.useEffect(() => {
    fetchProject(true);
  }, [fetchProject]);

  const { value: storyboard, setDoFetch: fetchStoryboard } = useStoryboard(
    props.storyboardId
  );
  React.useEffect(() => {
    fetchStoryboard(true);
  }, [fetchStoryboard]);

  const { frames, orderedFrameIds } = useFrames(props.storyboardId);

  const framesPerPage = 6;
  let perPageFrameIds = [];
  for (var i = 0; i < orderedFrameIds.length; i += framesPerPage) {
    perPageFrameIds.push(orderedFrameIds.slice(i, i + framesPerPage));
  }

  return perPageFrameIds.map((pageFrameIds, pageIdx) => {
    return (
      <div className="storyboard-pdf">
        <div className="page" key={`page-${pageIdx + 1}`}>
          <div className="header">
            <Typography>Title: {storyboard.name}</Typography>
            <Typography>Project: {project.name}</Typography>
          </div>
          <div className="frames">
            {pageFrameIds.map(frameId => {
              const frame = frames[frameId];
              return (
                <div className="frame" key={frameId}>
                  <Typography className="frame-title">{frame.name}</Typography>
                  <FrameCard frame={frame} />
                  <div className="description">
                    <p className="text">
                      <span>{frame.description || ""}</span>
                    </p>
                    <div className="blank-lines">
                      <hr size={0.1} />
                      <hr />
                      <hr />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="footer">
            <Typography variant="body2">
              Made with Viso Labs Storyboard
            </Typography>
            <Typography variant="body2">
              {pageIdx + 1} / {perPageFrameIds.length}
            </Typography>
          </div>
        </div>
      </div>
    );
  });
}
