import React, { useEffect } from "react";
import { Paper, Typography } from "@material-ui/core";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Input
} from "@material-ui/core";
import Backend from "./../../backend";
import "./ProjectCard.scss";

function NewProjectDialog(props) {
  const { open, onClose } = props;
  const [value, setValue] = React.useState("");

  const inputRef = React.createRef();
  useEffect(() => {
    setValue("New Project");
  }, [open]);

  return (
    <Dialog
      open={open}
      onEntered={() => inputRef.current.select()}
      onClose={() => onClose(null)}
      onKeyPress={e => {
        if (e.key === "Enter") {
          onClose({ name: value });
        }
      }}
    >
      <DialogContent>
        <DialogContentText>Enter Project Title:</DialogContentText>
        <React.Fragment>
          <Input
            inputRef={inputRef}
            autoFocus={true}
            type="text"
            value={value}
            onChange={e => setValue(e.target.value)}
          />
        </React.Fragment>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose({ name: value })} disableRipple={true}>
          Create
        </Button>
        <Button
          onClick={() => {
            onClose(null);
          }}
          disableRipple={true}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function NewProjectCard(props) {
  const { onNewProjectCreated } = props;
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  function handleDialogClose(data) {
    if (data) {
      Backend.createProject(data.name).then(newProject => {
        setIsDialogOpen(false);
        onNewProjectCreated(newProject.id);
      });
    } else {
      setIsDialogOpen(false);
    }
  }

  return (
    <>
      <NewProjectDialog open={isDialogOpen} onClose={handleDialogClose} />
      <Paper
        className="project-card new-project-card"
        onClick={() => {
          setIsDialogOpen(true);
        }}
      >
        <Typography className="title unselectable" variant="h5">
          + New Project
        </Typography>
      </Paper>
    </>
  );
}
