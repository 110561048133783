import Backend from "../../backend";
import { useFetch } from "./../Utility/hooks";

export function useProject(projectId) {
  const getProject = async => Backend.getProject(projectId);
  return useFetch(getProject, null);
}

export function useProjects(doInitialFetch) {
  const getProjects = async => Backend.getProjects();
  return useFetch(getProjects, []);
}

export function useAllProjects() {
  const getAllProjects = async => Backend.getAllProjects();
  return useFetch(getAllProjects, []);
}

export function useUserInfo(userId) {
  const queryFunction = async () => {
    if (!userId) {
      return [];
    }
    return Backend.getUserInfo(userId);
  };
  return useFetch(queryFunction, []);
}

export function useProjectUsers(projectId) {
  const queryFunction = async () => {
    if (!projectId) {
      return [];
    }
    return Backend.getProjectUsers(projectId);
  };
  return useFetch(queryFunction, []);
}

export function useProjectStoryboards(projectId) {
  const queryFunction = async () => {
    if (!projectId) {
      return [];
    }
    return Backend.getStoryboards(projectId).catch(() => []);
  };
  return useFetch(queryFunction, []);
}
