import { useSelector } from "react-redux";

export function useCheckUserValid(adminOnly) {
  const session = useSelector(state => state.session.sessionUser);

  let isUserValid = null;
  if (
    session.user &&
    Object.keys(session.user).length > 0
    // && (session.user.claims && session.user.claims.approved)
  ) {
    isUserValid = true;
    if (adminOnly) {
      if (!session.user.claims || !session.user.claims.approved) {
        isUserValid = false;
      }
    }
  } else {
    isUserValid = false;
  }

  const isUserLoading = session.isLoading;

  return { isUserValid, isUserLoading };
}
