import React from "react";
import { Tooltip, CircularProgress } from "@material-ui/core";
import "./Toolbox.scss";

export default function ToolboxItem(props) {
  const { onClick, tooltip, inProgress } = props;

  const inProgressClass = inProgress ? "in-progress" : "";

  return (
    <Tooltip title={tooltip || ""} placement="right-start">
      <button
        className={`toolbox-item ${inProgressClass}`}
        onClick={() => {
          !inProgress && onClick && onClick();
        }}
      >
        {props.children}
        {inProgress ? (
          <CircularProgress className="toolbox-item-progress" />
        ) : null}
      </button>
    </Tooltip>
  );
}
