import React from "react";
import { useDispatch, useSelector } from "react-redux";
import OutlinerItem from "../../Outliner/OutlinerItem";
import OutlinerInstanceText from "./../../Outliner/OutlinerInstanceText";
import BackgroundIcon from "@material-ui/icons/Landscape";
import PropIcon from "@material-ui/icons/CardTravel";
import CharacterIcon from "@material-ui/icons/Person";
import { Typography } from "@material-ui/core";

export default function InstanceOutliner(props) {
  const fdl = useSelector(state => state.fdl.present);
  const frameEditor = useSelector(state => state.frameEditor);
  const dispatch = useDispatch();

  const characters = fdl.characters || {};

  let backgrounds;
  if (fdl.backgrounds) {
    backgrounds = fdl.backgrounds;
  } else if (fdl.background && Object.keys(fdl.background).length > 0) {
    backgrounds = { bg1: fdl.background };
  } else {
    backgrounds = {};
  }

  const instanceProps = fdl.props || {};

  return (
    <div>
      <OutlinerItem selectable={false}>
        <Typography className="outliner-header">Scene</Typography>
      </OutlinerItem>
      {Object.keys(characters).map(id => {
        const character = characters[id];
        const name = character.name || character.asset.name;
        return (
          <OutlinerItem
            key={id}
            onClick={() => {
              dispatch({
                type: "SET_SELECTION",
                id: id
              });
            }}
            selectable={true}
            selected={id === frameEditor.selection}
          >
            <CharacterIcon />
            <OutlinerInstanceText
              value={name}
              saveValue={value => {
                dispatch({
                  type: "UPDATE_CHARACTER",
                  charId: id,
                  updates: { name: value }
                });
              }}
              selected={id === frameEditor.selection}
            />
          </OutlinerItem>
        );
      })}
      {Object.keys(backgrounds).map(id => {
        const background = backgrounds[id];
        const name = background.name || background.asset.name;
        return (
          <OutlinerItem
            key={id}
            onClick={() => {
              dispatch({
                type: "SET_SELECTION",
                id: id
              });
            }}
            selectable={true}
            selected={id === frameEditor.selection}
          >
            <BackgroundIcon />
            <OutlinerInstanceText
              value={name}
              saveValue={value => {
                dispatch({
                  type: "UPDATE_BACKGROUND",
                  backgroundId: id,
                  updates: { name: value }
                });
              }}
              selected={id === frameEditor.selection}
            />
          </OutlinerItem>
        );
      })}
      {Object.keys(instanceProps).map(id => {
        const prop = instanceProps[id];
        const name = prop.name || prop.asset.name;
        return (
          <OutlinerItem
            key={id}
            onClick={() => {
              dispatch({
                type: "SET_SELECTION",
                id: id
              });
            }}
            selectable={true}
            selected={id === frameEditor.selection}
          >
            <PropIcon />
            <OutlinerInstanceText
              value={name}
              saveValue={value => {
                dispatch({
                  type: "UPDATE_PROP",
                  propId: id,
                  updates: { name: value }
                });
              }}
              selected={id === frameEditor.selection}
            />
          </OutlinerItem>
        );
      })}
    </div>
  );
}
