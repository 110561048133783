import React, { useEffect } from "react";
import { useProps } from "../Props/hooks";
import Gallery from "../Gallery/Gallery";
import "./PropGallery.scss";

export default function PropGallery(props) {
  const { projectId, onAssetClicked, refresh, setRefresh } = props;
  const { value: propAssets, setDoFetch } = useProps(projectId);

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
      setDoFetch(true);
    }
  }, [refresh, setRefresh, setDoFetch]);

  return (
    <Gallery
      assets={propAssets}
      onAssetClicked={onAssetClicked}
      loadAssets={setDoFetch}
    />
  );
}
