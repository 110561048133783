import React, { useEffect } from "react";
import Backend from "../../backend";

import { useFetch } from "./../Utility/hooks";

export function useStoryboard(storyboardId) {
  const getStoryboard = async => Backend.getStoryboard(storyboardId);
  return useFetch(getStoryboard, null);
}

// TODO: Swith to a tidier useFetch pattern
export function useFrames(storyboardId) {
  let cancel = React.useRef(false);

  const [state, _setState] = React.useState({
    frames: {},
    orderedFrameIds: [],
    shouldFetch: true,
    fetching: false,
    hasFetched: false
  });

  const updateState = update => {
    return _setState({ ...state, ...update });
  };

  const frames = state.frames;
  const setFrames = val => updateState({ frames: val });
  const orderedFrameIds = state.orderedFrameIds;
  const setOrderedFrameIds = val => updateState({ orderedFrameIds: val });
  const shouldFetch = state.shouldFetch;
  const setShouldFetch = val => updateState({ shouldFetch: val });
  const fetching = state.fetching;
  const setFetching = val => updateState({ fetching: val });
  const hasFetched = state.hasFetched;

  useEffect(() => {
    cancel.current = false;
  }, [shouldFetch]);

  useEffect(() => {
    updateState({
      shouldFetch: true
    });
  }, [storyboardId]);

  useEffect(() => {
    if (shouldFetch && !fetching) {
      if (!cancel) {
        updateState({ shouldFetch: false, fetching: true });
      }

      if (storyboardId === null) {
        updateState({
          orderedFrameIds: [],
          frames: [],
          fetching: false,
          shouldFetch: false,
          hasFetched: true
        });
      } else {
        Backend.getStoryboard(storyboardId)
          .then(storyboard => {
            const projectId = storyboard.projectId;
            const orderedFrames = storyboard.orderedFrames || [];
            return [projectId, orderedFrames];
          })
          .then(result => {
            let [projectId, orderedFrameIds] = result;
            if (!projectId) {
              updateState({
                orderedFrameIds: [],
                frames: [],
                fetching: false,
                shouldFetch: false,
                hasFetched: true
              });
            } else {
              Backend.getFrames(storyboardId, projectId).then(frames => {
                Promise.all(frames).then(frames => {
                  frames = frames.reduce((map, obj) => {
                    map[obj.id] = obj;
                    return map;
                  }, {});

                  const nonOrderedFrameIds = Object.keys(frames)
                    .filter(id => !orderedFrameIds.includes(id))
                    .sort((a, b) => (frames[a].name > frames[b].name ? 1 : -1));

                  if (nonOrderedFrameIds) {
                    orderedFrameIds = orderedFrameIds.filter(
                      frameId => frameId in frames
                    );
                  }

                  if (!cancel.current) {
                    updateState({
                      orderedFrameIds: [
                        ...orderedFrameIds,
                        ...nonOrderedFrameIds
                      ],
                      frames: frames,
                      fetching: false,
                      shouldFetch: false,
                      hasFetched: true
                    });
                  }
                });
              });
            }
          });
      }
    }
    return () => (cancel.current = true);
  }, [storyboardId, shouldFetch, fetching]);

  return {
    frames,
    setFrames,
    orderedFrameIds,
    setOrderedFrameIds,
    shouldFetch,
    setShouldFetch,
    fetching,
    setFetching,
    hasFetched
  };
}
