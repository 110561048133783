import React, { useRef } from "react";
import { useDrop, useDrag } from "react-dnd";
import "./DND.scss";

const ITEM_TYPE = "CARD";

export default function DNDItem(props) {
  const { id, index, moveCard, draggable } = props;

  const ref = useRef(null);

  const [{ isOver }, drop] = useDrop({
    accept: ITEM_TYPE,
    drop(item, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    collect: monitor => ({
      isOver: monitor.isOver()
    })
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: ITEM_TYPE, id: id, index: index, ref: ref },
    canDrag: draggable,
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  drop(drag(ref));

  const hoverClass = isOver ? "dnd-hover" : "";
  const dragClass = isDragging ? "dnd-drag" : "";
  const draggableClass = draggable ? "dnd-draggable" : "";

  return (
    <div
      className={`dnd-container ${hoverClass} ${dragClass} ${draggableClass}`}
      ref={ref}
      classes={[]}
    >
      {props.children}
      <div className="dnd-hover-indicator" />
    </div>
  );
}
