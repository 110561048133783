import React, { useEffect } from "react";
import { useProps } from "./hooks";
import Gallery from "../Gallery/Gallery";
import PixabayGallery from "./../Pixabay/PixabayGallery";
import "./PropMultiGallery.scss";
import { Tabs, Tab } from "@material-ui/core";
import Backend from "./../../../backend";

function PublicProps(props) {
  const { onAssetClicked } = props;
  const { value: propAssets, setDoFetch } = useProps("public");
  useEffect(() => {
    setDoFetch(true);
  }, []);

  return <Gallery assets={propAssets} onAssetClicked={onAssetClicked} />;
}

function ProjectProps(props) {
  const { projectId, onAssetClicked } = props;
  const { value: propAssets, setDoFetch } = useProps(projectId);

  useEffect(() => {
    setDoFetch(true);
  }, []);

  return <Gallery assets={propAssets} onAssetClicked={onAssetClicked} />;
}

export default function PropMultiGallery(props) {
  const { projectId, onAssetClicked } = props;

  const [currentTab, setCurrentTab] = React.useState(0);

  const tabs = ["My Project", "Viso Labs", "Search"];

  function onImageClicked(image) {
    return Backend.pixabayImport(projectId, image, "prop")
      .then(assetId => {
        return Backend.__getProp(projectId, assetId);
      })
      .then(asset => {
        onAssetClicked(asset);
      })
      .catch(error => {
        console.log(error);
      });
  }

  return (
    <div
      className="prop-multi-gallery"
      // container
      // item
      // direction="column"
    >
      <Tabs
        className="prop-tabs"
        value={currentTab}
        onChange={(e, v) => {
          setCurrentTab(v);
        }}
      >
        {tabs.map(name => (
          <Tab
            key={name}
            className="prop-tab"
            label={name}
            disableRipple
            disableFocusRipple
          />
        ))}
      </Tabs>
      {(tabs[currentTab] === "My Project" && (
        <ProjectProps projectId={projectId} onAssetClicked={onAssetClicked} />
      )) ||
        (tabs[currentTab] === "Viso Labs" && (
          <PublicProps onAssetClicked={onAssetClicked} />
        )) ||
        (tabs[currentTab] === "Search" && (
          <PixabayGallery
            onImageClicked={onImageClicked}
            searchForIsolatedOnly={true}
          />
        ))}
    </div>
  );
}
